// import React, { Fragment, useEffect, useState } from "react";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import { axiosAPI } from "../Axios/Axios";
// import { Box } from "@mui/material";

// export default function AllLanguage({
//   value,
//   setValue,
//   label,
//   style,
//   languageName,
//   setLanguageName,
// }) {
//   const [languages, setLanguages] = useState([]);
//   const [selectedLanguages, setSelectedLanguages] = useState([]);
//   useEffect(() => {
//     const getAllLanguages = async () => {
//       const result = await axiosAPI.get("/getAllLanguages");
//       const { success, data } = result.data;
//       if (success === true) {
//         setLanguages(data);
//       } else {
//         setLanguages([]);
//       }
//     };
//     getAllLanguages();
//   }, []);

//   const handleChange = (e) => {
//     const selectedValue = e.target.value;

//     // Check if the language is already selected
//     const isLanguageSelected = selectedLanguages.some(
//       (language) => language.l_id === selectedValue.l_id
//     );

//     if (!isLanguageSelected) {
//       // If the language is not already selected, update the state
//       setSelectedLanguages((prevSelectedLanguages) => [
//         ...prevSelectedLanguages,
//         selectedValue
//       ]);

//       setValue(selectedValue);

//       // Assuming languages is an array of objects
//       const selectedLanguage = languages.find(
//         (language) => language.l_id === selectedValue.l_id
//       );

//       if (selectedLanguage) {
//         setLanguageName(selectedLanguage.language);
//       } else {
//         // Handle the case when the selected language is not found
//         setLanguageName("");
//       }
//     }
//   };



//   console.log("test123",languages[0])
//   return (
//     <FormControl sx={{ width: "100%" }}>
//       <Select
//         value={value}
//         // label={label}
//         placeholder={label}
//         displayEmpty
//         variant="outlined"
//         onChange={handleChange}
//         sx={{
//           padding: "10px 12px",
//           fontFamily: "Poppins",
//           ...style,
//         }}
//       >
//         <MenuItem value="" disabled>
//           <span style={{ color: "#bcbcbc" }}>{label}</span>
//         </MenuItem>
//         {languages &&
//           languages.map((val, index) => {
//             return (
//               <MenuItem key={index} value={val}>
//                 {val.language}
//               </MenuItem>
//             );
//           })}
//       </Select>
//     </FormControl>
//   );
// }
import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200, // Adjust the maximum height as needed
      width: 250,
    },
  },
};

export default function AllLanguage({
  value,
  setValue,
  label,
  style,
  languageName,
  setLanguageName,
  jobreqlang,
  jobRequiredLanguages1
}) {

  const [languages, setLanguages] = useState([]);
  const [langbackup, setlangbackup] = useState([]);

  useEffect(()=>{
    if(jobreqlang){
      const updatedLanguages = languages.filter(lang => !jobreqlang.some(jobReq => jobReq.language === lang.language));
      setLanguages(updatedLanguages);
    }
  },[jobreqlang])

  // console.log(languages);


  useEffect(() => {
    const getAllLanguages = async () => {
      const result = await axiosAPI.get("/getAllLanguages");
      const { success, data } = result.data;
      if (success === true) {
        setLanguages(data);
        // console.log(data);
        setlangbackup(data)
      } else {
        setLanguages([]);
      }
    };
    getAllLanguages();
  }, []);

  const handleChange = (e) => {
    const selectedLanguage = languages.find(
      (language) => language.l_id === e.target.value.l_id
    );
    setValue(selectedLanguage); // { l_id: 6, language: "Arabic", code: "ar" }
    setLanguageName(selectedLanguage?.language);  //Arabic
  };
  const Labels = useSelector((state) => state.allLabels.labels);

  
  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
       MenuProps={MenuProps}
        value={value}
        onChange={handleChange}
        placeholder={label}
        displayEmpty
        variant="outlined"
        sx={{ padding: "10px 12px",fontSize:"14px",  fontFamily: "Poppins", ...style }}
      >
        <MenuItem value="" disabled>
          <span style={{ color: "#bcbcbc" }}>{label}</span>
        </MenuItem>
        {languages &&
          languages.map((val, index) => {
            const isDisabled = jobRequiredLanguages1?.some(jobLang => jobLang.language1 === val.language);
            return (
              <MenuItem key={index} value={val} disabled={isDisabled} >
                {val.language}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
