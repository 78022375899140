import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import { axiosAPI } from "../Axios/Axios";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";

const Country = ({ value, setValue, style, countryId,userchangedcountry,setuserchangedcountry  }) => {
  const dispatch = useDispatch();
  const [Countries, setCountries] = useState([]);
  const usertype = localStorage.getItem("userType");

  useEffect(() => {
    const getCountries = async () => {
      const result = await axiosAPI.get("/getCountries");
      const { success, data } = await result.data;
      if (success === true) {
        setCountries(data);
        if (countryId) {
          const selectedCountry = data.find((country) => country.id == countryId);
          if (selectedCountry) {
            setValue(selectedCountry);
          }
        }
      } else {
        setCountries([]);
      }
    };
    getCountries();
  }, [countryId]);

  const Labels = useSelector((state) => state.allLabels.labels);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setuserchangedcountry(true)
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      <Autocomplete
        value={value}
        onChange={handleChange}
        options={Countries}
        sx={{ ...style }}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        readOnly={usertype === "Recruiter"}
        renderInput={(params) => (
          <TextField {...params} placeholder={Labels?.SelectCountry} />
        )}
        noOptionsText={Labels?.notAvailable}
      />
    </FormControl>
  );
};

export default Country;
