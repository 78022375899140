import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  Stack,
  TextField,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import { axiosAPI } from "../../Axios/Axios";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { errorNotify, succesNotify } from "../../CommonCode/Commonfunc";
import Country from "../../CommonCode/Country";
import State from "../../CommonCode/State";
import TextfieldCustom from "../../Components/TextfieldCustom";
import { useSelector } from "react-redux";
import { useState } from "react";
import TransgenderIcon from "@mui/icons-material/Transgender";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import PublicIcon from "@mui/icons-material/Public";
import BungalowIcon from "@mui/icons-material/Bungalow";
import HomeIcon from "@mui/icons-material/Home";
import { PublicOutlined } from "@mui/icons-material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "60%",
    sm: "50%",
    md: "40%",
    lg: "30%",
  },
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

export default function CompanyBranches({ open, handleClose, data }) {
  const [isButtonDisable, setButtonDisable] = useState(false);
  const [place, setLocations] = useState(data || []);
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState("");
  const [address, setaddress] = useState("");
  const [countryId, setCountryId] = useState(null);
  const [stateId, setStateId] = useState(null);
  const submitLocations = async () => {
    if (!country || !state || !city) {
      return errorNotify(Labels.allFieldsMandatory);
    } else if (city.length > 200) {
      errorNotify(Labels.cityValidation);
    } else {
      setButtonDisable(true);
      const newData = {
        country: country.id,
        state: state.id,
        city: city,
        address: address,
      };
      const newPlace = [...place, newData];

      const postData = {
        country: country.id,
        state: state.id,
        city: city,
        address: address,
        language: localStorage.getItem("language"),
      };
      const result = await axiosAPI.post("/addCompanyBranch", postData);
      const { status, message } = result.data;
      if (status === true) {
        succesNotify(message);
        handleClose();
        setButtonDisable(false);
      } else {
        errorNotify(message);
        setButtonDisable(false);
      }
    }
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  const [userchangedcountry, setuserchangedcountry] = useState(null);
  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 1.5 }}
      >
        <Typography variant="h6" sx={{ color: "#1C614E" }}>
          {Labels.CompanyBranches}
        </Typography>
        <HighlightOffIcon
          sx={{
            color: "#1C614E",
            cursor: "pointer",
          }}
          onClick={handleClose}
        />
      </Stack>
      <Box sx={{ p: 1.5 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {Labels?.Country}
        </Typography>
        <Country
          value={country}
          setValue={setCountry}
          setStateId={setState}
          userchangedcountry={userchangedcountry}
          setuserchangedcountry={setuserchangedcountry}
          style={{
            width: "100%",
          }}
        />
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {Labels?.State}
        </Typography>
        <State
          value={state}
          setValue={setState}
          country={country}
          userchangedcountry={userchangedcountry}
          setuserchangedcountry={setuserchangedcountry}
          style={{
            width: "100%",
          }}
        />
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {Labels?.City}
        </Typography>
        <TextfieldCustom
          style={{
            width: "100%",
          }}
          label={Labels?.City}
          onChange={(e) => setCity(e.target.value)}
        />
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {Labels?.Address}
        </Typography>

        <TextField
          fullWidth
          placeholder={Labels?.Address}
          variant="outlined"
          onChange={(e) => setaddress(e.target.value)}
          
          multiline
          rows={4}
          inputProps={{
            maxLength: 255, // Limit the input to 255 characters
          }}
          InputProps={{
            sx: {
              "& .MuiOutlinedInput-input": {
                padding: "12px", // Adjusts padding inside the input
              },
            },
          }}
        />

        <Stack direction="row" justifyContent="center" sx={{ p: 1.5 }}>
          <Button
            variant="solid"
            fullWidth
            sx={{
              background: "#1C614E",
              borderRadius: 3,
              color: "#fff",
              "&:hover": {
                backgroundColor: "#1C614E",
              },
              "&:active": {
                backgroundColor: "#1C614E",
              },
              "&:disabled": { color: "white", backgroundColor: "#1C614E" },
            }}
            disabled={isButtonDisable}
            onClick={submitLocations}
          >
            {Labels?.Save}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}
