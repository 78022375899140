import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Stack,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ShareIcon from "@mui/icons-material/Share";
import PlaceIcon from "@mui/icons-material/Place";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { axiosAPI } from "../../Axios/Axios";
import { differenceInDays } from "date-fns";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import {
  errorNotify,
  useStyles,
  warningNotify,
  succesNotify,
} from "../../CommonCode/Commonfunc";
import EnquiryMessageModal from "./EnquiryMessageModal";
import { useSelector } from "react-redux";
import JobSeekerActivationModal from "../JobseekerProfile/JobseekerDashboard/JobSeekerActivationModal";
import CustomButton from "../../Components/CustomButton";
import BackBtn from "../../../Images/left-arrow.png";
import { Textarea } from "@mui/joy";
import { profanitySend } from "../../Profanity/Profanity";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: "15px",
  },
}));

export default function NewJobDetailedPage({ jobid, savefn, refreshState }) {
  const redirect = useNavigate();
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);
  const classes = useStyles();
  const { id } = useParams();
  const [isButtonDisable, setButtonDisable] = useState(false);
  const [jobDetails, setJobDetails] = useState({});
  const [dynamicFields, setDynamicFields] = useState([]);

  useEffect(() => {
    if (jobDetails && Object.keys(jobDetails).length != 0) {
      jobDetails?.Jobinformations.forEach((info) => {
        if (info.key === "others") {
          if (info?.value) {
            setDynamicFields(JSON.parse(info?.value));
          } else {
            setDynamicFields([]);
          }
        }
      });
    }
  }, [jobDetails]);

  const [openEnquiryModal, setEnquiryModal] = useState(false);
  const [showSkeleton, setshowSkeleton] = useState(true);
  const uid = localStorage.getItem("uid");
  const [openActivationModal, setActivationModal] = useState(false);
  const jobSeekerLoginDetails = useSelector(
    (state) => state.jobSeekerloginDetails.loginDetails
  );

  const getjobdetails = async () => {
    if (id || jobid) {
      const result = await axiosAPI.post(
        `/getJobInfo?jobid=${jobid ? jobid : id}`
      );
      const { success, data } = result.data;

      if (!result.data.success) {
        redirect(`/jobList`);
      }
      if (success === true) {
        setJobDetails(data);
        setshowSkeleton(false);
      } else {
        setshowSkeleton(false);
      }
    }
  };

  useEffect(() => {
    getjobdetails();
  }, [refreshState]);

  useEffect(() => {
    getjobdetails();
  }, [id, jobid]);

  useEffect(() => {
    const getProfile = async () => {
      try {
        const uid = localStorage.getItem("uid");
        const postdata = {
          jobseekerid: uid,
        };
        const result = await axiosAPI.post("/profile", postdata);
        const { success, data, message } = result.data;

        if (
          data?.displayName == null ||
          data?.emailid === null ||
          data?.countryName == null ||
          data?.stateName == null ||
          data?.city === null ||
          data?.address1 === null ||
          data?.education.length === 0
        ) {
          localStorage.setItem("applystatus", false);
        } else {
          localStorage.setItem("applystatus", true);
        }
      } catch (error) {
        //// console.log(error.message);
      }
    };

    getProfile();
  }, []);

  const ApplyjobProfanity = () => {
    if (jobDetails.receiveCoverLetter) {
      const data = {
        coverLetter: coverLetter,
      };

      let profanityDetected;
      for (const key in data) {
        if (profanitySend(ProfanityList, data[key])) {
          profanityDetected = true;
          break;
        } else {
          profanityDetected = false;
        }
      }

      if (profanityDetected == false) {
        ApplyJob();
      } else {
        warningNotify(Labels.ProfanityLabel);
      }
    } else {
      ApplyJob();
    }
  };

  const ApplyJob = async () => {
    if (localStorage.getItem("applystatus") == "true") {
      setButtonDisable(true);
      if (jobDetails.receiveCoverLetter) {
        if (coverLetter) {
          const postData = {
            jobid: jobid ? jobid : id,
            jobseekerid: uid,
            coverLetter,
          };
          try {
            const result = await axiosAPI.post("/applyjob", postData);
            const { success, message } = result.data;

            if (success == true) {
              const jobData = {
                jobId: jobDetails.eja_id,
                jobName: jobDetails.jobName,
              };
              localStorage.setItem("appliedJobDetails", success);
              setButtonDisable(false);
              succesNotify(Labels.jobAppliedSuccess);
              setOpenCoverLetter(false)
              redirect("/similarJobs", { state: jobData });
            } else {
              setButtonDisable(false);
              setOpenCoverLetter(false)
              errorNotify(message);
            }
          } catch (error) {
            setButtonDisable(false);
            setOpenCoverLetter(false)
            errorNotify(error);
          }
        } else {
          errorNotify(Labels.writeCoverLetter);
          setButtonDisable(false);
        }
      } else {
        setButtonDisable(false);
        const postData = {
          jobid: jobid ? jobid : id,
          jobseekerid: uid,
          coverLetter,
        };
        try {
          const result = await axiosAPI.post("/applyjob", postData);
          const { success, message } = result.data;

          if (success === true) {
            const jobData = {
              jobId: jobDetails.eja_id,
              jobName: jobDetails.jobName,
            };
            localStorage.setItem("appliedJobDetails", success);
            setButtonDisable(false);
            succesNotify(Labels.jobAppliedSuccess);
            redirect("/similarJobs", { state: jobData });
          } else {
            setButtonDisable(false);
            errorNotify(message);
          }
        } catch (error) {
          setButtonDisable(false);
          errorNotify(error);
        }
      }
    } else {
      warningNotify(Labels.FillData);
      redirect("/jobseekerProfile");
    }
  };

  const handleLinkClick = () => {
    const email = ""; // Replace with the recipient's email address
    const subject = "Job Application";
    const body = `Job Link : https://50plus.zone/detailedJobView/${
      jobid ? jobid : id
    }`;

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const sentEnquiryMessage = () => {
    if (!jobSeekerLoginDetails?.activatedOn) {
      setActivationModal(true);
    } else {
      setEnquiryModal(true);
    }
  };

  const Labels = useSelector((state) => state.allLabels.labels);

  const [openCoverLetter, setOpenCoverLetter] = useState(false);
  const [coverLetter, setCoverLetter] = useState("");

  const handlecompanynameClick = (employerId) => {
    redirect(`/Companydetailview/${employerId}`);
    //// console.log(`Employer ID clicked: ${employerId}`);
  };

  const savefn1 = async (data) => {
    try {
      savefn(data);
    } catch (error) {
      const savefn = async (data) => {
        const postdata1 = {
          language: "en",
          jobid: data.eja_id,
          type: data.save_status ? "unsave" : "save",
          language: localStorage.getItem("language"),
        };

        try {
          const result = await axiosAPI.post("/saveJob", postdata1);
          const { status, message } = result.data; // Destructure status and message
          if (status === true) {
            succesNotify(message); // Notify success
            getjobdetails();
          }
        } catch (error) {
          //// console.log(error.message);
        }
      };

      savefn(data);
    }
  };

  const daysRemaining = jobDetails?.remainDays;

  const goToPreviousRoute = () => {
    if (window.history.length <= 2) {
      window.location.href = "/jobseekerDashboard";
    } else {
      window.history.back();
    }
  };

  const allValuesEmpty = jobDetails?.Jobinformations?.every(obj => !obj.value.trim() || obj.value === "[]");

  return (
    <>
      <EnquiryMessageModal
        open={openEnquiryModal}
        handleClose={setEnquiryModal}
        jobId={jobid}
        id={id}
      />
      <JobSeekerActivationModal
        open={openActivationModal}
        handleClose={setActivationModal}
      />

      {id && (
        <img
          height={40}
          style={{ cursor: "pointer" }}
          src={BackBtn}
          onClick={goToPreviousRoute}
        />
      )}

      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sx={{
          background: "white",
          padding: "20px 10px",
          height: `85vh`,
          overflowY: "auto",
        }}
      >
        {showSkeleton ? (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* Profile Picture Skeleton */}
              <Skeleton variant="circular" width={60} height={60} />
              <Skeleton
                variant="rectangular"
                width={`100%`}
                height={100}
                style={{ marginLeft: 16 }}
              />
            </div>
            <br />
            <Skeleton variant="rectangular" width={"100%"} height={150} />
            <br />
            <Skeleton variant="rectangular" width={"100%"} height={150} />
          </>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                mb={1}
                spacing={4}
              >
                <Stack direction="row">
                  <ShareIcon
                    onClick={handleLinkClick}
                    sx={{
                      color: "#2B4947",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#C0C0C0",
                      },

                      width: 30,
                      height: 30,
                    }}
                  />
                </Stack>
                <Stack direction="row">
                  {jobDetails.save_status === 0 ? (
                    <BookmarkBorderOutlinedIcon
                      onClick={() => {
                        savefn1(jobDetails);
                      }}
                      sx={{
                        color: "#2B4947",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#C0C0C0",
                        },

                        width: 30,
                        height: 30,
                      }}
                    />
                  ) : null}

                  {jobDetails.save_status === 1 ? (
                    <BookmarkAddedIcon
                      onClick={() => {
                        savefn1(jobDetails);
                      }}
                      sx={{
                        color: "#2B4947",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#C0C0C0",
                        },

                        width: 30,
                        height: 30,
                      }}
                    />
                  ) : null}
                </Stack>
              </Stack>
            </Grid>
            {/* Logo Grid */}

            <Grid item xs={12} sm={12} md={12} lg={12} xl={3}>
              <Box
                className={classes.box30}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "top",
                  height: "100%",
                }}
              >
                <Avatar
                  src={
                    jobDetails.companyLogo ? `${jobDetails.companyLogo}` : null
                  }
                  style={{ width: 150, height: 160 }}
                >
                  {jobDetails.companyLogo ? null : (
                    <>
                      {jobDetails?.jobName
                        ? jobDetails.jobName
                            .split(" ")
                            .map((word) => word[0].toUpperCase())
                            .join("")
                        : null}
                    </>
                  )}
                </Avatar>
              </Box>
            </Grid>

            {/* Card Grid */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={9}>
              <Box className={classes.box70}>
                <Typography
                  variant="h5"
                  sx={{ color: "#2B4947", fontWeight: 600 }}
                >
                  {jobDetails?.jobName}
                </Typography>
                <Typography
                  variant="subtilte1"
                  sx={{ fontSize: "1.2rem", cursor: "pointer" }}
                  onClick={() => handlecompanynameClick(jobDetails?.employerid)}
                >
                  {jobDetails?.employer}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "1.2rem",
                    color: "#1C614E",
                    margin: 0,
                  }}
                >
                  {jobDetails?.companyAddress}, {jobDetails?.country}
                  &nbsp;
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.1rem", color: "#808080" }}
                >
                  {jobDetails?.createdOn} - {jobDetails?.jobseekers}{" "}
                  {jobDetails?.jobseekers == 1
                    ? Labels?.Applicant
                    : Labels?.Applicants}
                  <br />
                  {daysRemaining > 0 ? (
                    <span
                      style={{
                        color:
                          daysRemaining && daysRemaining < 5
                            ? "red"
                            : "#808080",
                      }}
                    >
                      {daysRemaining}{" "}
                      {daysRemaining == 1
                        ? Labels.dayRemaining
                        : Labels.daysRemaining}
                    </span>
                  ) : (
                    <span style={{ color: "red" }}>{Labels.expired} </span>
                  )}
                </Typography>
              </Box>
              <Box className={classes.box70}>
                <Stack direction="row" spacing={1}>
                  {/* Details Chips */}
                  <Grid container direction="row">
                    <Grid item xs={4} direction="column">
                      {jobDetails?.officePresenceName && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          mx={1}
                        >
                          <LightTooltip
                            title={Labels.officePresencetooltip}
                            arrow
                          >
                            <Chip
                              // key={index}
                              label={jobDetails?.officePresenceName}
                              size="medium"
                              sx={{
                                color: "#BA8C04",
                                background: "#FFFFFF",
                                borderRadius: 1,
                                width: "100%",
                                fontSize: "1.1rem",
                                margin: "10px 12px 10px 0",
                                border: "1px solid #BA8C04",
                              }}
                            />
                          </LightTooltip>
                        </Stack>
                      )}

                      {jobDetails.officePercentage != null && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          mx={1}
                        >
                          <LightTooltip
                            title={Labels.officePercentagetooltip}
                            arrow
                          >
                            <Chip
                              key={""}
                              label={
                                jobDetails.officePercentage == 100
                                  ? `${jobDetails.officePercentage}%`
                                  : jobDetails.officePercentage == 0
                                  ? `${jobDetails.remotePercentage}%`
                                  : jobDetails.officePercentage > 0 &&
                                    jobDetails.officePercentage < 100
                                  ? `${jobDetails.officePercentage}% (${Labels.Office})`
                                  : null
                              }
                              size="medium"
                              sx={{
                                color: "#BA8C04",
                                background: "#FFFFFF",
                                borderRadius: 1,
                                width: "100%",
                                fontSize: "1.1rem",
                                margin: "10px 12px 10px 0",
                                border: "1px solid #BA8C04",
                              }}
                            />
                          </LightTooltip>
                        </Stack>
                      )}
                    </Grid>

                    <Grid item xs={4} direction="column">
                      {jobDetails?.workingHours != null &&
                      jobDetails?.hourPeriod != null ? (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          mx={1}
                        >
                          <LightTooltip
                            title={Labels.workingHourstooltip}
                            arrow
                          >
                            <Chip
                              key={""}
                              label={`${jobDetails?.workingHours} hrs/${jobDetails?.hourPeriod}`}
                              size="medium"
                              sx={{
                                color: "#BA8C04",
                                background: "#FFFFFF",
                                borderRadius: 1,
                                width: "100%",
                                fontSize: "1.1rem",
                                margin: "10px 12px 10px 0",
                                border: "1px solid #BA8C04",
                              }}
                            />
                          </LightTooltip>
                        </Stack>
                      ) : null}

                      {jobDetails?.salary != null &&
                      jobDetails?.currencySymbol != null &&
                      jobDetails?.salaryPeriodName != null ? (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          mx={1}
                        >
                          <LightTooltip title={Labels.salarytooltip} arrow>
                            <Chip
                              key={""}
                              label={`${jobDetails?.salary} ${jobDetails?.currencySymbol}/${jobDetails?.salaryPeriodName} `}
                              size="medium"
                              sx={{
                                color: "#BA8C04",
                                background: "#FFFFFF",
                                borderRadius: 1,
                                width: "100%",
                                fontSize: "1.1rem",
                                margin: "10px 12px 10px 0",
                                border: "1px solid #BA8C04",
                              }}
                            />
                          </LightTooltip>
                        </Stack>
                      ) : null}
                    </Grid>

                    <Grid item xs={4} direction="column">
                      {jobDetails.jobTypes ? (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          mx={1}
                          //   my={2}
                        >
                          {jobDetails.jobTypes
                            .split(",")
                            .map((jobType, i) => (
                              <LightTooltip
                                title={Labels.jobtitletooltip}
                                arrow
                                key={jobType}
                              >
                                <Chip
                                  key={jobType}
                                  label={jobType}
                                  size="medium"
                                  sx={{
                                    color: "#BA8C04",
                                    background: "#FFFFFF",
                                    borderRadius: 1,
                                    width: "100%",
                                    fontSize: "1.1rem",
                                    margin: "10px 12px 10px 0",
                                    border: "1px solid #BA8C04",
                                  }}
                                />
                              </LightTooltip>
                            ))
                            .slice(0, 2)}
                        </Stack>
                      ) : null}
                      {jobDetails?.typePercentage && (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          mx={1}
                        >
                          <LightTooltip
                            title={Labels.typepercentagetooltip}
                            arrow
                          >
                            <Chip
                              key={""}
                              label={`${jobDetails?.typePercentage}0%`}
                              size="medium"
                              sx={{
                                color: "#BA8C04",
                                background: "#FFFFFF",
                                borderRadius: 1,
                                width: "100%",
                                fontSize: "1.1rem",
                                margin: "10px 12px 10px 0",
                                border: "1px solid #BA8C04",
                              }}
                            />
                          </LightTooltip>
                        </Stack>
                      )}
                    </Grid>
                  </Grid>
                </Stack>
              </Box>
            </Grid>

            {/* Content Grid */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ margin: 4 }}
            >
              <Box className={classes.box70}>
                {/* Level,travelling, employee count etc.. */}
                <Stack>
                  <Grid container spacing={2}>
                    {/* seniorityLevel */}
                    {jobDetails?.seniorityLevel ? (
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                          <Typography> {Labels.ExperienceLevel} </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                          <Typography>{jobDetails?.seniorityLevel}</Typography>
                        </Grid>
                      </>
                    ) : null}

                    {/* Travel and travelPercentage */}
                    {jobDetails?.travelPercentage ? (
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                          <Typography> {Labels.Travelling} </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                          <Typography>
                            {jobDetails?.travelPercentage} %
                          </Typography>
                        </Grid>
                      </>
                    ) : null}

                    {/*companySize */}
                    {jobDetails?.companySize?.length ? (
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                          <Typography> {Labels?.Employees} </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                          <Typography>{jobDetails?.companySize}</Typography>
                        </Grid>
                      </>
                    ) : null}

                    {/*qualifications*/}
                    {jobDetails?.qualifications?.length ? (
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                          <Typography> {Labels.Required} </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                          <Typography fontSize="1.2rem">
                            {jobDetails.qualifications &&
                              Array.isArray(jobDetails.qualifications) &&
                              jobDetails.qualifications.map((data, index) => (
                                <>
                                  {" "}
                                  {data.educationName}
                                  {index !==
                                    jobDetails.qualifications.length - 1 && ","}
                                </>
                              ))}
                          </Typography>
                        </Grid>
                      </>
                    ) : null}

                    {/* Additional Qualifications */}
                    {jobDetails?.additionalQualification && (
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                          <Typography> {Labels?.Nicetohave} </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                          <Typography>
                            {jobDetails.additionalQualification}
                          </Typography>
                        </Grid>
                      </>
                    )}

                    {/* Languages Required */}
                    {jobDetails?.languages &&
                      jobDetails?.languages?.length > 0 && (
                        <>
                          <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                            <Typography> {Labels.languageRequired} </Typography>
                          </Grid>
                          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                            <Typography>:</Typography>
                          </Grid>
                          <Grid item xs={12} sm={7} md={7} lg={8} xl={9}>
                            {/* Languages Required */}
                            {jobDetails?.languages && (
                              <Grid item xs={12}>
                                <TableContainer
                                  component={Paper}
                                  sx={{ boxShadow: "none", border: "none" }}
                                >
                                  <Table
                                    aria-label="language requirement table"
                                    sx={{ border: "none" }}
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            p: 0.2, // Reduced padding
                                            fontWeight: "bold",
                                            width: "230px",
                                            border: "none", // Remove cell borders
                                          }}
                                        >
                                          {Labels.language}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            p: 0.2, // Reduced padding
                                            fontWeight: "bold",
                                            width: "200px",
                                            border: "none", // Remove cell borders
                                          }}
                                        >
                                          {Labels.Spoken}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            p: 0.2, // Reduced padding
                                            fontWeight: "bold",
                                            border: "none", // Remove cell borders
                                          }}
                                        >
                                          {Labels.Written}
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {jobDetails?.languages?.map(
                                        (lang, index) => (
                                          <TableRow
                                            key={index}
                                            sx={{ border: "none" }} // Remove row borders
                                          >
                                            <TableCell
                                              sx={{ p: 0.2, border: "none" }}
                                            >
                                              {lang.language}
                                            </TableCell>
                                            <TableCell
                                              sx={{ p: 0.2, border: "none" }}
                                            >
                                              {lang.spoken}
                                            </TableCell>
                                            <TableCell
                                              sx={{ p: 0.2, border: "none" }}
                                            >
                                              {lang.written}
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </Grid>
                            )}
                          </Grid>
                        </>
                      )}

                    {/* Category Section */}
                    <>
                      <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                        <Typography> {Labels?.categories} </Typography>
                      </Grid>
                      <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                        <Typography>:</Typography>
                      </Grid>
                      <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                        <Chip
                          label={jobDetails?.jobCategoryName}
                          size="medium"
                          sx={{
                            color: "#BA8C04",
                            background: "#FFFFFF",
                            borderRadius: 1,
                            minWidth: 120,
                            fontSize: "1.1rem",
                            border: "1px solid #BA8C04",
                          }}
                        />
                      </Grid>
                    </>
                    {/* Skills Required Section */}
                    {jobDetails?.jobSkills?.length ? (
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                          <Typography> {Labels?.ExpectedSkills} </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                          {jobDetails?.jobSkills?.length
                            ? jobDetails.jobSkills.map((skill) => (
                                <Chip
                                  key={skill.id}
                                  label={skill?.skillName}
                                  size="medium"
                                  sx={{
                                    color: "#BA8C04",
                                    background: "#FFFFFF",
                                    borderRadius: 1,
                                    minWidth: 120,
                                    fontSize: "1.1rem",
                                    margin: "0px 12px 10px 0",
                                    border: "1px solid #BA8C04",
                                  }}
                                />
                              ))
                            : null}
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </Stack>
                <br />
                <Stack spacing={2} direction="row">
                  <Stack direction="column" sx={{ width: "100%" }}>
                    {/* Additional Languages */}
                    <Box sx={{ flexGrow: 1, width: "100%" }}>
                      <Grid container spacing={2}>
                        {jobDetails.additionalLanguage &&
                        jobDetails.additionalLanguage.length > 0 ? (
                          <>
                            <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                              <Typography>
                                {Labels.AdditionalLanguages}{" "}
                              </Typography>
                            </Grid>
                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                              <Typography>:</Typography>
                            </Grid>
                            <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                              <Typography>
                                {Array.isArray(jobDetails.additionalLanguage) &&
                                  jobDetails.additionalLanguage.map(
                                    (data, index) => (
                                      <span>
                                        {data.language1}
                                        {index !==
                                          jobDetails?.additionalLanguage
                                            .length -
                                            1 && ", "}
                                      </span>
                                    )
                                  )}
                              </Typography>
                            </Grid>
                          </>
                        ) : null}
                      </Grid>
                    </Box>
                  </Stack>
                </Stack>
              </Box>

              <Box className={classes.box70}>
                {/* --------------------------------who is hiring-------------------------- */}
                {jobDetails.recruiterName != null ? (
                  <Stack>
                    <Box
                      sx={{
                        margin: "15px 10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 600,
                        }}
                      >
                        {Labels?.WhoisHiring}
                      </Typography>
                    </Box>

                    <Stack direction="row">
                      <Box
                        sx={{
                          minHeight: "80px",
                          maxHeight: "80px",
                          overflowY: "hidden",
                          margin: "10px",
                        }}
                      >
                        <Avatar
                          src={
                            jobDetails.recruiterLogo
                              ? `${jobDetails.recruiterLogo}`
                              : null
                          }
                          alt=" "
                          style={{ width: 70, height: 70, margin: "10px" }}
                        />
                      </Box>

                      <Box
                        sx={{
                          minHeight: "80px",
                          maxHeight: "80px",
                          overflowY: "hidden",
                          margin: "15px",
                        }}
                      >
                        {jobDetails?.recruiterName != null ? (
                          <Typography
                            variant="subtitle1"
                            sx={{
                              color: "#2B4947",
                              fontWeight: 600,
                              // color: jobId === val.eja_id ? "#fff" : "#000",
                            }}
                          >
                            {jobDetails?.recruiterName?.length > 20
                              ? jobDetails?.recruiterName.slice(0, 20) + "..."
                              : jobDetails?.recruiterName}
                          </Typography>
                        ) : null}
                        {jobDetails.designation != null ? (
                          <Typography
                            variant="body1"
                            sx={{
                              color: "#2B4947",
                              // fontWeight: 600,
                              // color: jobId === val.eja_id ? "#fff" : "#000",
                            }}
                          >
                            {jobDetails?.designation?.length > 20
                              ? jobDetails?.designation.slice(0, 20) + "..."
                              : jobDetails?.designation}
                          </Typography>
                        ) : null}
                      </Box>
                    </Stack>
                  </Stack>
                ) : null}

                {/* -------------------- Job Description -------------------- */}
                <Box sx={{ margin: "15px 10px" }}>
                  {jobDetails?.Jobinformations?.length || dynamicFields?.length ? (
                    <Grid container spacing={2}>
                      {/* Job Description Header */}
                      {!allValuesEmpty &&
                      <Grid item xs={12}>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 600 }}
                        >
                          {Labels?.jobDescription}
                        </Typography>
                      </Grid> }
                      

                      {/* Job Details */}
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          {/* Job Details Items */}
                          {jobDetails?.whatYouWillDo && (
                            <Grid item xs={12} md={12} lg={12} xl={12}>
                              <Card variant="outlined" sx={{ p: 1 }}>
                                <Typography
                                  sx={{ fontWeight: 500 }}
                                  variant="subtitle1"
                                >
                                  {Labels.Whatyouwilldo}
                                </Typography>
                                <Typography variant="body2">
                                  {jobDetails.whatYouWillDo}
                                </Typography>
                              </Card>
                            </Grid>
                          )}

                          {jobDetails?.whoWeWouldLikeToHave && (
                            <Grid item xs={12} md={12} lg={12} xl={12}>
                              <Card variant="outlined" sx={{ p: 1 }}>
                                <Typography
                                  sx={{ fontWeight: 500 }}
                                  variant="subtitle1"
                                >
                                  {Labels.WhoWeWouldLikeToHave}
                                </Typography>
                                <Typography variant="body2">
                                  {jobDetails.whoWeWouldLikeToHave}
                                </Typography>
                              </Card>
                            </Grid>
                          )}

                          {jobDetails?.weWorkWith && (
                            <Grid item xs={12} md={12} lg={12} xl={12}>
                              <Card variant="outlined" sx={{ p: 1 }}>
                                <Typography
                                  sx={{ fontWeight: 500 }}
                                  variant="subtitle1"
                                >
                                  {Labels.WeWorkWith}
                                </Typography>
                                <Typography variant="body2">
                                  {jobDetails.weWorkWith}
                                </Typography>
                              </Card>
                            </Grid>
                          )}

                          {jobDetails?.WouldBeAdvantageToHave && (
                            <Grid item xs={12} md={12} lg={12} xl={12}>
                              <Card variant="outlined" sx={{ p: 1 }}>
                                <Typography
                                  sx={{ fontWeight: 500 }}
                                  variant="subtitle1"
                                >
                                  {Labels.WouldBeAdvantageToHave}
                                </Typography>
                                <Typography variant="body2">
                                  {jobDetails.WouldBeAdvantageToHave}
                                </Typography>
                              </Card>
                            </Grid>
                          )}

                          {/* Dynamic Fields */}
                          {dynamicFields?.length
                            ? dynamicFields?.map((item, index) => (
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  lg={12}
                                  xl={12}
                                  key={index}
                                >
                                  <Card variant="outlined" sx={{ p: 1 }}>
                                    <Typography
                                      sx={{ fontWeight: 500 }}
                                      variant="subtitle1"
                                    >
                                      {item?.question}
                                    </Typography>
                                    <Typography variant="body2">
                                      {item?.answer}
                                    </Typography>
                                  </Card>
                                </Grid>
                              ))
                            : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : null}
                </Box>

             

                {jobDetails?.companyName && (
                  <Box sx={{ margin: "15px 10px" }}>
                    <Typography
                      sx={{ margin: "15px auto 0px", fontWeight: 500 }}
                      variant="subtitle1"
                    >
                      {Labels.Hiringfor} {jobDetails?.companyName}
                    </Typography>
                  </Box>
                )}

                {jobDetails?.Experience && (
                  <Box
                    sx={{
                      margin: "15px 10px",
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      {Labels?.Experience}
                    </Typography>
                    <Typography variant="body1" sx={{ my: 2 }}>
                      {jobDetails?.experianceYears}
                    </Typography>
                  </Box>
                )}

                {jobDetails?.CompanyLink && (
                  <Box
                    sx={{
                      margin: "15px 10px",
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {Labels?.CompanyLink}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#000000A1" }}>
                      {jobDetails.companyLink}&nbsp;
                    </Typography>
                  </Box>
                )}
              </Box>

              {/* Apply and enquiry button */}
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  my: 2,
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                }}
              >
                <Stack direction="row" spacing={2} my={2}>
                  {jobDetails?.status_name === "Applied" ? (
                    <Button
                      variant="contained"
                      disabled
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: "10px",
                        backgroundColor: "#1C614E",
                        "&:hover": {
                          backgroundColor: "#1C614E",
                        },
                        "&:active": {
                          backgroundColor: "#1C614E",
                        },
                        "&:disabled": {
                          color: "white",
                          backgroundColor: "grey",
                        },
                      }}
                    >
                      {Labels?.Applied}
                    </Button>
                  ) : jobDetails?.status_name === "Shortlisted" ? (
                    <Button
                      variant="contained"
                      disabled
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: "10px",
                        backgroundColor: "#1C614E",
                        "&:hover": {
                          backgroundColor: "#1C614E",
                        },
                        "&:active": {
                          backgroundColor: "#1C614E",
                        },
                        "&:disabled": {
                          color: "white",
                          backgroundColor: "grey",
                        },
                      }}
                    >
                      {Labels.Shortlisted}
                    </Button>
                  ) : (
                    <>
                      <Stack>
                        <CustomButton
                          variant="contained"
                          sx={{
                            textTransform: "capitalize",
                            borderRadius: "10px",
                            backgroundColor: "#1C614E",
                            "&:hover": {
                              backgroundColor: "#1C614E",
                            },
                            "&:active": {
                              backgroundColor: "#1C614E",
                            },
                            "&:disabled": {
                              color: "white",
                              backgroundColor: "#1C614E",
                            },
                          }}
                          label={Labels?.ApplyNow}
                          onClick={() => {
                            if (jobDetails.receiveCoverLetter === 1) {
                              setOpenCoverLetter(true);
                            } else {
                              ApplyjobProfanity();
                              // Handle the case when receiveCoverLetter is not 1 (e.g., show an error message)
                            }
                          }}
                          disabled={isButtonDisable}
                        />
                      </Stack>
                    </>
                  )}
                  <Stack>
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "capitalize",
                        borderRadius: "10px",
                        backgroundColor: "#1C614E",
                        "&:hover": {
                          backgroundColor: "#1C614E",
                        },
                        "&:active": {
                          backgroundColor: "#1C614E",
                        },
                        "&:disabled": {
                          color: "white",
                          backgroundColor: "#1C614E",
                        },
                      }}
                      onClick={sentEnquiryMessage}
                    >
                      {Labels?.enquiry}
                    </Button>
                  </Stack>
                </Stack>
              </Stack>

              <Dialog
                open={openCoverLetter}
                onClose={() => setOpenCoverLetter(false)}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ p: 1.5 }}
                >
                  <Typography variant="h6" sx={{ color: "#1C614E" }}>
                    {Labels.CoverLetter}
                  </Typography>
                  <HighlightOffIcon
                    sx={{
                      cursor: "pointer",
                      color: "#1C614E",
                    }}
                    onClick={() => setOpenCoverLetter(false)}
                  />
                </Stack>
                <DialogContent>
                  <Box
                    sx={{
                      maxHeight: "80vh",
                      overflowY: "auto",
                      overflowX: "hidden",
                      p: 1.5,
                      minWidth: "300px",
                    }}
                  >
                    <Textarea
                      autoFocus
                      type="text"
                      multiline
                      minRows={7}
                      width="100%"
                      variant="outlined"
                      placeholder={Labels.Typehere}
                      value={coverLetter}
                      onChange={(e) => setCoverLetter(e.target.value)}
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  {/* <Button onClick={() => setOpenCoverLetter(false)}>Cancel</Button> */}
                  {/* <Button onClick={ApplyJob} color="success"> */}
                  <Button
                    variant="solid"
                    fullWidth
                    sx={{
                      background: "#1C614E",
                      color: "white",
                      borderRadius: 3,
                      "&:hover": {
                        backgroundColor: "#1C614E",
                      },
                      "&:active": {
                        backgroundColor: "#1C614E",
                      },
                      "&:disabled": {
                        color: "white",
                        backgroundColor: "grey",
                      },
                    }}
                    disabled={isButtonDisable}
                    onClick={ApplyjobProfanity}
                    // disabled={isButtonDisable}
                  >
                    {Labels?.ApplyNow}
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
