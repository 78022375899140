import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosAPI } from "../views/Axios/Axios";

const initialState = {
  loading: false,
  error: "",
  labels: {},
};

const getAllLabels = createAsyncThunk(
  "api/getFileNames",
  async (languageCode) => {
    return await axiosAPI
      // .get(`/getFileNames?language=${languageCode}`)
      .get(`/getFileNames?language=en`)
      .then((response) => {
        return response.data.data;
      });
  }
);

const getAllLabelSlice = createSlice({
  name: "allLabels",
  initialState,
  extraReducers: {
    [getAllLabels.pending]: (state) => {
      state.loading = true;
    },
    [getAllLabels.fulfilled]: (state, { payload }) => {
      state.labels = payload;
      state.loading = false;
    },
    [getAllLabels.rejected]: (state) => {
      state.error = "something went wrong";
      state.loading = false;
    },
  },
});

export { getAllLabels };

export default getAllLabelSlice.reducer;
