import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Stack,
  TextField,
  Typography,
  Chip,
  Switch,
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
} from "@mui/material";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import React, { useEffect, useState, useRef, createRef } from "react";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import CustomButton from "../../Components/CustomButton";
import PhoneIcon from "@mui/icons-material/Phone";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import TourOutlinedIcon from "@mui/icons-material/TourOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useSelector } from "react-redux";
import { axiosupload, axiosAPI } from "../../Axios/Axios";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import Designation from "../../CommonCode/Designation";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import {
  errorNotify,
  succesNotify,
  validatePhoneNumber,
  warningNotify,
} from "../../CommonCode/Commonfunc";
import Country from "../../CommonCode/Country";
import RecruiterCountry from "../../CommonCode/RecruiterCountry";
import State from "../../CommonCode/State";
import { useStyles } from "../../CommonCode/Commonfunc";
import EmployerPasswordChangeModal from "./EmployerPasswordChangeModal";
import IconLabelTextField from "../../Components/iconLabelText";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import SimpleBackdrop from "../../CommonCode/SimpleBackdrop";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Close, Label } from "@mui/icons-material";
import CompanyType from "../../CommonCode/CompanyType";
import BusinessCategory from "../../CommonCode/BusinessCategory";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import FlagIcon from "@mui/icons-material/Flag";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { getCompanyProfile } from "../../../redux/CompanyDetailSlice";
import { useDispatch } from "react-redux";
import YesNo from "../../CommonCode/YesNo";
import JobPostingDuration from "../../CommonCode/JobDuration";
import InputWithLabel from "../../Components/InputWithLabel";
import { useDropzone } from "react-dropzone";
import { Select } from "@material-ui/core";
import { profanityfn } from "../../Profanity/Profanity";

const ProfileFrom = ({ device, callbackfn}) => {

  const [employerData, setEmployerData] = useState({});
  const [isButtonDisabled1, setButtonDisabled1] = useState(false);
  const [isButtonDisabled2, setButtonDisabled2] = useState(false);
  const [isButtonDisabled3, setButtonDisabled3] = useState(false);
  const [isButtonDisabled4, setButtonDisabled4] = useState(false);
  const [isButtonDisabled5, setButtonDisabled5] = useState(false);
  const [backdropOpen, setBackdrop] = useState(false);
  const [openPasswordResetModal, setPasswordResetModal] = useState(false);
  const [businessCategories, setBusinessCategories] = useState([]);
  const [companyTypes, setCompanyTypes] = useState("");
  const [headQuarters, setHeadQuarters] = useState(null);
  const [headQuarterState, setHeadQuarterState] = useState(null);
  const Labels = useSelector((state) => state.allLabels.labels);
  const [countryCode, setCountryCode] = useState("");
  const usertype = localStorage.getItem("userType");
  useEffect(() => {
    getEmployerData();
  }, []);

  const getEmployerData = async () => {
    setBackdrop(true);
    const result = await axiosAPI.post("/getCompanyProfile");
    const { status, data, message } = result.data;
    if (status) {
      setEmployerData(data);
      if (
        data.businessCategory &&
        Array.isArray(data.businessCategory) &&
        data.businessCategory.length !== 0
      ) {
        const arr = [];
        data.businessCategory.map((data) => {
          arr.push(data.id);
        });
        setBusinessCategories(arr);
      }
    } else {
      setBackdrop(false);
    }
  };

  var {
    address1,
    companyDescription,
    companyName,
    contactName,
    designationName,
    companyType,
    countrycode,
    headQuarterCity,
    headQuarterCountry,
    headOfCountryName,
    businessCategory,
    countryid,
    // logoPath,
    contactNumber,
    stateid,
    city,
    emailid,
    userType,
    recruiterName,
    recruiterNumber,
    recruiterCountryCode,
    // receiveCoverLetter,
    // allowContactByMail,
    // showYourProfile,
  } = employerData;
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  const handleChange = (e) => {
    setEmployerData({
      ...employerData,
      [e.target.name]: profanityfn(ProfanityList, e.target.value),
    });
  };
  const updateEmployerProfile = async () => {
    setButtonDisabled1(true);
    if (countryCode) {
      employerData.countryCode = countryCode;
    }
    if (companyTypes) {
      employerData.companyType = companyTypes;
    }
    // if (businessCategories.length !== 0) {
    //   employerData.businessCategory = businessCategories
    //     .toString()
    //     .replace(/(^,|,$|,,+)/g, "");
    // } else {
    //   employerData.businessCategory = null;
    // }
    if (!companyName) {
      errorNotify(Labels.companyNameRequired);
      setButtonDisabled1(false);
    } else if (companyName.length > 200) {
      errorNotify(Labels.companyNameValidation);
      setButtonDisabled1(false);
    } else if (contactName.length > 200) {
      errorNotify(Labels.contactNameValidation);
      setButtonDisabled1(false);
    } else if (!designationName || !designationName.trim()) {
      errorNotify(Labels.designationRequired);
      setButtonDisabled1(false);
    } else if (!contactName || !contactName.trim()) {
      errorNotify(Labels.contactNameRequired);
      setButtonDisabled1(false);
    } else if (!contactNumber) {
      errorNotify(Labels.phoneNumberRequired);
      setButtonDisabled1(false);
    } else if (!/^\d*$/.test(contactNumber)) {
      errorNotify(Labels.phoneNumberValidation);
      setButtonDisabled1(false);
    } else if (contactNumber.length < 7) {
      errorNotify(Labels.contactMinValidation);
      setButtonDisabled1(false);
    } else if (contactNumber.length > 14) {
      errorNotify(Labels.contactMaxValidation);
      setButtonDisabled1(false);
    } else {
      setEmployerData({...employerData,language:localStorage.getItem("language")})
      const result = await axiosAPI.post("/updateEmpProfile", employerData);
      const { success, message } = result.data;
      if (success) {
        setButtonDisabled1(false);
        succesNotify(message);
        getEmployerData();
        callbackfn()
        // routeChange(Math.random());
      } else {
        setButtonDisabled1(false);
        errorNotify(message);
      }
    }
  };

  return (
    <>
      {/* ---------------Contact Person Designation------------------ */}
      <div style={{ width: "240px", mt: 3 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          // alignItems="left"
          mt={2}
        >
          {employerData?.userType === "Recruiter" ? (
            <>
              <label style={{ color: "#413E3E", width: 80, fontSize: "12px" }}>
                {Labels.FullName}
              </label>
              <input
                name="recruiterName"
                value={employerData?.recruiterName}
                onChange={(e) => handleChange(e)}
                style={{
                  width: "60%",
                  padding: "4px",
                  fontSize: "12px",
                  marginRight: 6,
                }}
                placeholder={Labels?.ContactName}
              />
            </>
          ) : (
            <>
              <label style={{ color: "#413E3E", width: 80, fontSize: "12px" }}>
                {Labels.FullName}
              </label>
              <input
                name="contactName"
                value={employerData?.contactName}
                onChange={(e) => handleChange(e)}
                style={{
                  width: "60%",
                  padding: "4px ",
                  fontSize: "12px",
                  marginRight: 6,
                }}
                placeholder={Labels?.ContactName}
              />
            </>
          )}
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          // alignItems="left"
          sx={{ my: 1.5 }}
        >
          <label
            style={{
              color: "#413E3E",
              width: 50,
              fontSize: "12px",
              alignItems: "left",
            }}
          >
            {Labels.Title}
          </label>
          <input
            name="designationName"
            value={employerData.designationName}
            onChange={(e) => handleChange(e)}
            style={{
              width: "60%",
              padding: "4px",
              fontSize: "12px",
              alignItems: "right",
              marginRight: 6,
            }}
            placeholder={Labels?.designation}
          />
        </Stack>
        {/* ---------------Contact Person Number------------------ */}

        <Stack
          direction="row"
          justifyContent="space-between"
          // alignItems="left"
          sx={{ my: 1.5 }}
        >
          {employerData?.userType === "Recruiter" ? (
            <>
              <label
                style={{
                  color: "#413E3E",
                  width: 50,
                  fontSize: "12px",
                  alignItems: "left",
                }}
              >
                {Labels.Phone}
              </label>
              <input
                type="text"
                name="recruiterNumber"
                value={employerData?.recruiterNumber}
                onChange={(e) => handleChange(e)}
                style={{
                  width: "60%",
                  padding: "4px",
                  fontSize: "12px",
                  alignItems: "right",
                  marginRight: 6,
                }}
                placeholder={Labels?.recruiterNumber}
              />
            </>
          ) : (
            <>
              <label
                style={{
                  color: "#413E3E",
                  width: 60,
                  fontSize: "12px",
                  alignItems: "left",
                }}
              >
                {Labels.Phone}
              </label>
              <input
                name="contactNumber"
                value={employerData?.contactNumber}
                onChange={(e) => handleChange(e)}
                style={{
                  width: "60%",
                  padding: "4px",
                  fontSize: "12px",
                  alignItems: "right",
                  marginRight: 6,
                }}
                placeholder={Labels?.contactNumber}
              />
            </>
          )}
        </Stack>

        {/* ---------------Contact Person EmailID------------------ */}
        <Stack
          direction="row"
          justifyContent="space-between"
          // alignItems="center"
          sx={{ my: 1.5 }}
        >
          <label
            style={{
              color: "#413E3E",
              width: 50,
              paddingRight: 4,
              fontSize: "12px",
              alignItems: "left",
            }}
          >
            {Labels.Email}
          </label>
          <input
            type="email"
            name="emailid"
            value={
              employerData?.userType == "Recruiter"
                ? employerData?.recruiterEmailid
                : employerData?.emailid
            }
            readOnly
            style={{
              width: "60%",
              padding: "4px",
              fontSize: "12px",
              alignItems: "right",
              marginRight: 6,
            }}
            placeholder={Labels?.emailid}
            onChange={(e) => handleChange(e)} // Add this line if you want to capture changes
          />
        </Stack>
        {/* ---------------Save button------------------ */}
        <div style={{ display: "flex", justifyContent: "end", marginRight: 2 }}>
          <CustomButton
            onClick={updateEmployerProfile}
            label={Labels?.Save}
            style={{ padding: "5px 25px", marginRight: 6 }}
            disabled={isButtonDisabled1}
          />
        </div>
      </div>
    </>
  );
};
export default ProfileFrom;
