import React, { Fragment, useRef, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import { Skeleton, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { Dropdown } from "semantic-ui-react";
import { List, ListItem, ListItemText } from "@mui/material";

const JobCategory = ({ style, label, value, setValue, ref }) => {
  // value = jobcate.categoryName => Dental Specialist
  const inputRef = useRef(null);
  const [JobCategories, setJobCategories] = useState([]);
  // [{id:"1", categoryName:""}]

  const [finalvalue, setfinalvalue] = useState("");

  useEffect(() => {
    const filteredvalue = JobCategories.filter((a) => {
      return a.id == value.id;
    });
    //// console.log(filteredvalue);
    setfinalvalue(filteredvalue[0]);
  }, [value]);
  useEffect(() => {
    const handleScroll = (event) => {
      if (inputRef.current && inputRef.current.contains(event.target)) {
        event.preventDefault();
      }
    };

    if (inputRef.current) {
      inputRef.current.addEventListener("focus", () => {
        document.body.style.overflow = "hidden";
      });
      inputRef.current.addEventListener("blur", () => {
        document.body.style.overflow = "";
      });
    }
    document.addEventListener("wheel", handleScroll, { passive: false });

    return () => {
      document.removeEventListener("wheel", handleScroll);
    };
  }, []);

  useEffect(() => {
    const getjobCategories = async () => {
      const result = await axiosAPI.get("/getAllCategories");
      const { success, data } = await result.data;
      if (success === true) {
        const newObjects = [
          {
            categoryIcon: "/images/teeth.png",
            categoryName: " ",
            id: "",
            jobs: "",
          },
          {
            categoryIcon: "/images/heart.png",
            categoryName: " ",
            id: "",
            jobs: "",
          },
          {
            categoryIcon: "/images/heart.png",
            categoryName: " ",
            id: "",
            jobs: "",
          },
        ];

        const newData = [...data, ...newObjects];

        setJobCategories(newData);

        // setJobCategories(data);
        const filtered = data.filter((a) => {
          return a.id == value.id;
        });
        //// console.log(filtered);
      } else {
        setJobCategories([]);
      }
    };
    getjobCategories();
  }, []);

  // useEffect(()=>{
  //   //// console.log(value);
  // },value)

  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };
  const handleChange = (event, newValue) => {
    if (typeof newValue === "string") {
      // Handle free-text entry
      setValue({ categoryName: newValue });
    } else if (newValue && typeof newValue === "object") {
      // Handle selection from options
      setValue(newValue);
    }
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  //// console.log("JobCategories", JobCategories);

  return (
    <FormControl sx={{ width: "100%", overflowY: "auto" }}>
      <Autocomplete
        id="country-select-demo"
        ref={inputRef}
        autoComplete={`off`}
        value={finalvalue}
        onChange={handleChange}
        options={JobCategories}
        getOptionLabel={(option) => option.categoryName}
        // getOptionLabel={(option) => (option === "" ? "Job Category" : option.categoryName)}
        renderInput={(params) => (
          <TextField {...params} placeholder={Labels?.JobCategory} />
        )}
        renderOption={(props, option) => (
          <ListItem component="div" {...props} key={option.categoryName}>
            {option.categoryName} &nbsp;
          </ListItem>
        )}
        noOptionsText={Labels?.notAvailable}
      />

    </FormControl>
  );
};

export default JobCategory;
