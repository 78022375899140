import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import {profanityfn}  from "../Profanity/Profanity";

const Currency = ({ value, setValue, style }) => {
  const Labels = useSelector((state) => state.allLabels.labels);

  const [Currency, setCurrency] = useState([]);
  useEffect(() => {
    const getCurrency = async () => {
      const result = await axiosAPI.get("/getCurrency");
      const { success, data } = await result.data;
      if (success === true) {
        setCurrency(data);
      } else {
        setCurrency([]);
      }
    };
    getCurrency();
  }, []);
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)


  const handleChange = (event) => {
    setValue(profanityfn(ProfanityList,event.target.value));
  };
  return (
    <FormControl sx={{ width: "50%", mt: 1 }}>
      <span style={{ fontSize: "14px", fontFamily: "Poppins" }}>
       {Labels.Currency}
      </span>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={handleChange}
        fullWidth
        displayEmpty
        variant="outlined"
        sx={{
          padding: "10px 12px",
          fontSize: "14px",
          fontFamily: "Poppins",
          ...style,
        }}
      >
         <MenuItem value="" >
          <span style={{ color: "#bcbcbc", fontSize: "14px" }}>
            {Labels?.Currency}
          </span>
        </MenuItem>
        {Currency &&
          Currency.map((val, index) => {
            return (
              <MenuItem key={index} value={val.cl_id}>
                {val.currencySymbol} 
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

export default Currency;
