import React, { useState, useEffect } from "react";
import { Box, Dialog, Stack, TextField, Typography } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button } from "@mui/joy";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { axiosAPI } from "../../Axios/Axios";
import { errorNotify, succesNotify } from "../../CommonCode/Commonfunc";
import Qualification from "../../CommonCode/Qualification";
import TextfieldCustom from "../../Components/TextfieldCustom";
import { useSelector } from "react-redux";
import { profanityfn } from "../../Profanity/Profanity";
import Country from "../../CommonCode/Country";

export default function ({ open, handleClose,data }) {
  const data2 = {
    jseb_id: 28,
    createdOn: "2024-10-03T05:30:43.000000Z",
    status: null,
    jobseekerid: 2,
    educationID: 2,
    startYear: 2020,
    endYear: 2024,
    startMonth: "January",
    endMonth: "February",
    institution: "test",
    countryId: "3",
    CourseName: "Diploma",
  }; 
  
  const [isButtonDisable, setButtonDisable] = useState(false);
  const [educationhistory, setEducationhistory] = useState("");
  const [educationStartYear, setEducationStartYear] = useState(null);
  const [educationEndYear, setEducationEndYear] = useState(null);
  const [institution, setInstitution] = useState("");
  const [countryId, setCountryId] = useState(null);
  const today = new Date();
  const Labels = useSelector((state) => state.allLabels.labels);
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  const [userchangedcountry, setuserchangedcountry] = useState(null);

  useEffect(() => {
    if (data) {
      setEducationhistory(data.educationID);
      setInstitution(data.institution);
      setCountryId(parseFloat(data?.countryId));

      const startDate = new Date(data.startYear, getMonthIndex(data.startMonth));
      const endDate = new Date(data.endYear, getMonthIndex(data.endMonth));

      setEducationStartYear(startDate);
      setEducationEndYear(endDate);
    }
  }, []);

  // Helper function to get the correct month index based on the month name
  const getMonthIndex = (monthName='January') => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months.indexOf(monthName);
  };

  const submitEducationHistory = async (e) => {
    e.preventDefault();
    setButtonDisable(true);
    if (
      !educationhistory ||
      !institution ||
      !educationStartYear ||
      !educationEndYear
    ) {
      setButtonDisable(false);
      return errorNotify(Labels.allFieldsMandatory);
    } else if (institution.length > 200) {
      setButtonDisable(false);
      return errorNotify(Labels.institutionNameValidation);
    }

    const startDate = new Date(educationStartYear);
    const endDate = new Date(educationEndYear);

    const startYear = startDate.getFullYear().toString();
    const endYear = endDate.getFullYear().toString();
    const startMonth = new Intl.DateTimeFormat("en-US", {month: "long"}).format(startDate);
    const endMonth = new Intl.DateTimeFormat("en-US", {month: "long"}).format(endDate);

    if (startYear > endYear) {
      setButtonDisable(false);
      return errorNotify(Labels.startyearshouldnot);
    }

    const postData = {
      educationid: data?.jseb_id || null,
      educationID: educationhistory,
      institution: institution,
      startYear: startYear,
      endYear: endYear,
      startMonth: startMonth,
      endMonth: endMonth,
      countryId: countryId?.id,
    };
    const apiEndpoint = "/updateEducation";
    try {
      const result = await axiosAPI.post(apiEndpoint, postData);
      const { success, message } = result.data;

      if (success) {
        succesNotify(data ? Labels.educationUpdateSuccess : Labels.saveSuccess);
        handleClose();
      } else {
        errorNotify(Labels.somethingWrong);
      }
    } catch (error) {
      errorNotify(Labels.somethingWrong);
    } finally {
      setButtonDisable(false);
    }
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 2 }}
      >
        <Typography variant="h6" sx={{ color: "#1C614E" }}>
          {Labels?.EducationHistory}
        </Typography>
        <HighlightOffIcon
          sx={{ color: "#1C614E", cursor: "pointer" }}
          onClick={handleClose}
        />
      </Stack>
      <Box sx={{ p: 2 }}>
        {/* Qualifications */}
        <div style={{ margin: "10px 5px" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {Labels?.Qualifications}
          </Typography>
          <Qualification
            value={educationhistory}
            setValue={setEducationhistory}
            style={{ width: "100%" }}
          />
        </div>

        {/* Country */}
        <div style={{ margin: "10px 5px" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {Labels?.Country}
          </Typography>
          <Country
            value={countryId}
            setValue={setCountryId}
            countryId={countryId}
            userchangedcountry={userchangedcountry}
            setuserchangedcountry={setuserchangedcountry}
            style={{
              width: "100%",
              fontSize: "14px",
            }}
          />
        </div>

        {/* Institution */}
        <div style={{ margin: "10px 5px" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {Labels?.Institution}
          </Typography>
          <TextfieldCustom
            style={{ width: "100%" }}
            label={Labels?.Institution}
            name="institution"
            value={institution}
            onChange={(e) =>
              setInstitution(profanityfn(ProfanityList, e.target.value))
            }
          />
        </div>

        {/* Date Pickers */}
        <Stack
          direction={{ xs: "column", md: "row" }}
          sx={{ justifyContent: "space-between" }}
        >
          <Box sx={{ mx: 1 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              {Labels?.From}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                format="MM/yy"
                views={["month", "year"]}
                maxDate={today}
                value={educationStartYear}
                onChange={(newValue) => setEducationStartYear(newValue)}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
          </Box>

          <Box sx={{ mx: 1 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              {Labels?.To}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                format="MM/yy"
                views={["month", "year"]}
                minDate={educationStartYear}
                maxDate={today}
                value={educationEndYear}
                onChange={(newValue) => setEducationEndYear(newValue)}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
          </Box>
        </Stack>

        {/* Save Button */}
        <Stack direction="row" justifyContent="center" sx={{ p: 2 }}>
          <Button
            variant="solid"
            fullWidth
            sx={{
              background: "#1C614E",
              borderRadius: 3,
              "&:hover": { backgroundColor: "#1C614E" },
              "&:disabled": { color: "white", backgroundColor: "#1C614E" },
            }}
            disabled={isButtonDisable}
            onClick={submitEducationHistory}
          >
            {Labels?.Save}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}
