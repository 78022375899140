import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { axiosAPI } from "../../Axios/Axios";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Skills from "../../CommonCode/Skills";
import { errorNotify, succesNotify } from "../../CommonCode/Commonfunc";

import { useSelector } from "react-redux";
import { useState } from "react";
import SkillsNew from "../../CommonCode/SkillsNew";
import SkillsEdit from "../../CommonCode/SkillsEditJob";
import ContractTypeNew from "../../CommonCode/ContractTypeNew";

const ContractTypeAddModal = ({ open, handleClose, skills, setskills,contractname }) => {
  const [isButtonDisable, setButtonDisable] = useState(false);
  const postData = {
    contractType: skills,
    language: localStorage.getItem("language"),
  }; 

  const submitKeyskills = async () => {
    if (skills.length === 0) {
      return errorNotify(Labels.skillUpdation);
    } else {
      setButtonDisable(true);
      const result = await axiosAPI.post("/updateUserProfile", postData);
      const { success, message } = result.data;
      if (success === true) {
        succesNotify(message);
        handleClose();
        setButtonDisable(false);
      } else {
        errorNotify(message);
        setButtonDisable(false);
      }
    }
  };

  const Labels = useSelector((state) => state.allLabels.labels);
  const isLargeScreen = useMediaQuery("(min-width:600px)");
  
  return (
    <div>
      <Box>
        <ContractTypeNew value={skills} setValue={setskills} contractname={contractname}/>
      </Box>
      <Stack direction="row" justifyContent="end" sx={{ p: 1.5 }}>
        <Button
          variant="contained"
          sx={{
            textTransform: "capitalize",
            borderRadius: "3px",
            p: 0.5,
            background: "#6da09c26",
            borderColor: "#6da09c26",
            color: "#000",
            boxShadow: "none",
            "&:hover": {
              color: "#000",
              background: "#6da09c26",
            },
            "&:active": {
              color: "#000",
              backgroundColor: "#6da09c26",
            },
            "&:disabled": { color: "white", backgroundColor: "#1C614E" },
          }}
          onClick={submitKeyskills}
          disabled={isButtonDisable}
        >
          {Labels?.Update}
        </Button>
      </Stack>
    </div>
  );
};

export default ContractTypeAddModal;
