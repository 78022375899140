import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { FormControl } from "@mui/material";
import { useSelector } from "react-redux";
import {profanityfn}  from "../Profanity/Profanity";

const DesignationProfile = ({ value, setValue, style,label}) => {
  const [Designation, setDesignation] = useState([]);
  useEffect(() => {
    const getlanguage = async () => {
      const result = await axiosAPI.get("/getDesignations");
      const { success, data } = await result.data;
      if (success === true) {
        // // console.log(data);
        setDesignation(data);
      } else {
        setDesignation([]);
      }
    };
    getlanguage();
  }, []);
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  const handleChange = (event) => {
    setValue(profanityfn(ProfanityList,event.target.value));
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
        value={value}
        onChange={(event) => handleChange(event)}
        variant="outlined"
        sx={{ padding: "10px 12px", fontFamily: "Poppins", ...style }}
        displayEmpty
      >
        <MenuItem value="" disabled>
          <span style={{ color: "#bcbcbc" }}>{label}</span>
        </MenuItem>
        {Designation &&
          Designation.map((val, index) => (
            <MenuItem key={index} name={val.name} value={val.id}>
              {val.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default DesignationProfile;
