import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import { FormControl } from "@mui/material";
import {profanityfn}  from "../Profanity/Profanity";


const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300, // Adjust the maximum height as needed
     width: 350,
      overflowX:"auto",
        margin:"5px"
    },
  },
};

export default function ExperienceLevel({ value, setValue, style }) {
  const [experienceLevel, setExperienceLevel] = useState([]);
  useEffect(() => {
    const getExperienceLevel = async () => {
      const result = await axiosAPI.get("/getExperienceLevel");
      const { success, data } = await result.data;
      if (success === true) {
        setExperienceLevel(data);
      } else {
        setExperienceLevel([]);
      }
    };
    getExperienceLevel();
  }, []);
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)
  const handleChange = (event) => {
    setValue(profanityfn(ProfanityList,event.target.value));
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
        MenuProps={MenuProps}
        value={value}
        onChange={(event) => handleChange(event)}
        variant="outlined"
        sx={{ padding: "10px 12px",fontSize:"14px",  fontFamily: "Poppins", ...style }}
        displayEmpty
      >
        <MenuItem value="" disabled>
          <span style={{ color: "#bcbcbc" }}>{Labels?.ExperienceLevel}</span>
        </MenuItem>
        {experienceLevel &&
          experienceLevel.map((val, index) => {
            return (
              <MenuItem key={index} name={val.name} value={val.id}>
                {val.name}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
