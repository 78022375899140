import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import { Autocomplete, Skeleton, TextField } from "@mui/material";
import { profanityfn } from "../Profanity/Profanity";

const CompanyBranchAddress = ({
  value,
  setValue,
  country,
  style,
  ref,
  setstateID,
  setcityvalue,
}) => {
  const [Branches, setBrahches] = useState([]);
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  useEffect(() => {
    const getStates = async () => {
      const result = await axiosAPI.get(`/getBranchByCountry?countryid=${country?.id}`);
      const { status, data } = await result.data;
      if (status === true) {
        if (data) {
          setBrahches(data);
        }
      }
    };

    getStates();
  }, [country]);

  useEffect(() => {
    if (!value  || typeof( value )== "string") {
      setValue(Branches[0]);
    }
  }, [Branches]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value?.stateID && value?.city) {
      setstateID(value.stateID);
      setcityvalue(value.city);
    }
  }, [value]);

  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <FormControl sx={{ width: "99%", position:"relative", left:"4px" }}>
      <Autocomplete
        autoComplete={`off`}
        value={value}
        onChange={handleChange}
        options={Branches}
        sx={{ ...style }}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField {...params} placeholder={Labels?.SelectBranch} />
        )}
        noOptionsText={Labels?.notAvailable}
      />
    </FormControl>
  );
};

export default CompanyBranchAddress;
