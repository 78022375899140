import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  Stack,
  Typography,
  TextField,
  Autocomplete,
  Chip,
  styled,
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useSelector } from "react-redux";
import { axiosAPI } from "../../Axios/Axios";
import {
  errorNotify,
  infoNotify,
  succesNotify,
  warningNotify,
} from "../../CommonCode/Commonfunc";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: `white`,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1C614E",
  },
}));

const SkillsaddModal = ({ open, handleClose, skillsName }) => {
  const [selectedSkill, setSelectedSkill] = useState(null); // Skill object selected from Autocomplete
  const [skillDetail, setSkillDetail] = useState("");



  const [skillsList, setSkillsList] = useState([]);
  const [isButtonDisable, setButtonDisable] = useState(false);
  const Labels = useSelector((state) => state.allLabels.labels);
  const [newskills, setnewskills] = useState([]);
  const [test, settest] = useState(false);

  useEffect(() => {
    if (skillsName?.length > 0) {
      setnewskills(
        skillsName?.map((skill) => ({
          skillId: skill.id,
          detail: skill.detail,
        }))
      );
    }

    const fetchSkills = async () => {
      const result = await axiosAPI.get("/getSkills");
      const { success, data } = await result.data;
      if (success) {
        setSkillsList(data);
      } else {
        setSkillsList([]);
      }
    };
    fetchSkills();
  }, []);

  const handleAddSkill = () => {

    if(!skillDetail || !selectedSkill?.id){
      warningNotify(Labels.skillPercentIsRequired)
    }
    else{
      const skillExists = newskills.find((skill) => skill.skillId === selectedSkill?.id);
      if (skillExists) {
        infoNotify(`${selectedSkill.name} ${Labels.isalreadyadded}`); // Add this label to your Labels object
        return;
      }
  
      setnewskills([...newskills,{ skillId: selectedSkill?.id, detail: skillDetail },]);
      setSelectedSkill(null);
      setSkillDetail("");
  
      setTimeout(() => {
        settest(true);
      }, 500);
    }
    
  };

  useEffect(() => {
    const handleSubmit = async () => {
      if (newskills.length === 0) {
        return warningNotify(`${Labels?.skillUpdation} ${Labels.skillalreadyadded}`
        );
      }
      const postData = {
        skills: newskills,
        language: localStorage.getItem("language"),
      };

      setButtonDisable(true);
      const result = await axiosAPI.post("/updateUserProfile", postData);
      const { success, message } = result.data;
      if (success) {
        succesNotify(message);
        handleClose();
        setButtonDisable(false);
      } else {
        errorNotify(message);
        setButtonDisable(false);
      }
    };

    if (test) {
      handleSubmit();
    }
  }, [test]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <div style={{ width: "600px" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 1.5 }}
        >
          <Typography variant="h6" sx={{ color: "#1C614E" }}>
            {Labels?.KeySkills}
          </Typography>
          <HighlightOffIcon
            onClick={handleClose}
            sx={{ color: "#1C614E", cursor: "pointer" }}
          />
        </Stack>

        <Box sx={{ p: 1.5 }}>
          {/* Autocomplete for selecting a skill */}
          <Autocomplete
            value={selectedSkill}
            onChange={(e, newValue) => setSelectedSkill(newValue)}
            options={skillsList}
            getOptionLabel={(option) => option?.name || ""}
            renderInput={(params) => (
              <TextField
                {...params}
                label={Labels?.skills}
                placeholder={Labels?.selectSkill}
              />
            )}
            noOptionsText={Labels?.notAvailable}
          />

          {/* Text field for skill detail, only show if a skill is selected */}
          {selectedSkill && (
            <TextField
              type="number"
              inputProps={{
                min: 0,
                max: 100,
                step: 1,
              }}
              label={Labels?.skillpercent}
              value={skillDetail}
              onChange={(e) => {
                const value = e.target.value;
                // Ensure that only numbers between 0 and 100 are allowed
                if (
                  value === "" ||
                  (Number(value) >= 0 && Number(value) <= 100)
                ) {
                  setSkillDetail(value);
                }
              }}
              onBlur={(e) => {
                let value = Number(e.target.value);
                // If the value is outside the 0-100 range, set it to nearest boundary
                if (value < 0) {
                  value = 0;
                } else if (value > 100) {
                  value = 100;
                }
                setSkillDetail(value);
              }}
              fullWidth
              sx={{ mt: 2 }}
              variant="outlined"
            />
          )}

          {/* Button to add or update the skill and its detail */}

          <Button
            sx={{
              mt: 2,
              background: "#1C614E",
              borderRadius: 2,
              textTransform: "capitalize",
              color: "white",
              "&:hover": { backgroundColor: "#1C614E" },
              "&:disabled": { color: "white", backgroundColor: "#1C614E" },
            }}
            variant="contained"
            fullWidth
            onClick={handleAddSkill}
          >
            {Labels?.Update}
          </Button>
        </Box>
      </div>
    </Dialog>
  );
};

export default SkillsaddModal;
