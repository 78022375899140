import { React, useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Checkbox,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { errorNotify, succesNotify } from "../../../CommonCode/Commonfunc";
import { axiosAPI } from "../../../Axios/Axios";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function RecommendedJobs() {
  const redirect = useNavigate();
  const Labels = useSelector((state) => state.allLabels.labels);
  const uid = localStorage.getItem("uid");

  const [appliedJobs, setAppliedJobs] = useState(null); // applied job data
  const [deletedJobs, setdeletedJobs] = useState(null); // deleted job data
  const [selectedJobs, setSelectedJobs] = useState([]); // selected job list for deletion
  const [sortdirection, setsortdirection] = useState("DESC"); // sorting keyword for API

  const [appliedjobcount,setajcount] = useState(0)


  const getAppliedJobs = async () => {
    const postdata = {
      jobseekerid: uid,
      sort_direction: sortdirection,
    };
    try {
      if (uid) {
        const result = await axiosAPI.post("/getAppliedJobs", postdata);

        const { success, data, message } = result.data;
        if (success) {
          setajcount(data?.applied_Jobs?.length + data?.deleted_Jobs?.length)
          setAppliedJobs(data?.applied_Jobs?.reverse());
          setdeletedJobs(data?.deleted_Jobs?.reverse());
        } else {
          errorNotify(Labels.somethingWrong);
          //// console.log(message);
        }
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  useEffect(() => {
    getAppliedJobs(); // Same API having applied and deleted jobs data
  }, [sortdirection]);

  const handleCheckboxChange = (e, eja_id) => {
    if (e.target.checked) {
      setSelectedJobs([...selectedJobs, eja_id]);
    } else {
      setSelectedJobs(selectedJobs.filter((id) => id !== eja_id));
    }
  };
  // selects the checked job's id into an array

  const handleDeleteSelected = async () => {
    try {
      const result = await axiosAPI.post("/AppliedJobDelete", {
        jobids: selectedJobs,
      });
      const { success, status, data, message } = result.data;
      if (status) {
        succesNotify(message);
        setSelectedJobs([]);
        getAppliedJobs();
      }
    } catch (error) {}
  };
  // Deletes the array of selected job ids

  return (
    <Grid container spacing={2}>
      {!appliedJobs ? (
        <>
          <Skeleton
            variant="rounded"
            width={`100%`}
            sx={{ margin: "10px" }}
            height={60}
          />
          <Skeleton
            variant="rounded"
            width={`100%`}
            sx={{ margin: "10px" }}
            height={60}
          />
          <Skeleton
            variant="rounded"
            width={`100%`}
            sx={{ margin: "10px" }}
            height={60}
          />
          <Skeleton
            variant="rounded"
            width={`100%`}
            sx={{ margin: "10px" }}
            height={60}
          />
          <Skeleton
            variant="rounded"
            width={`100%`}
            sx={{ margin: "10px" }}
            height={60}
          />
          <Skeleton
            variant="rounded"
            width={`100%`}
            sx={{ margin: "10px" }}
            height={60}
          />
          <Skeleton
            variant="rounded"
            width={`100%`}
            sx={{ margin: "10px" }}
            height={60}
          />
          <Skeleton
            variant="rounded"
            width={`100%`}
            sx={{ margin: "10px" }}
            height={60}
          />
        </>
      ) : (
        <>
          <Grid item xs={12} sx={{ maxHeight: "85vh", overflowY: "auto" }}>
            <Paper
              sx={{
                borderRadius: 2,
                backgroundColor: "rgba(109, 160, 156, 0.12)",
                boxShadow: "none",
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ p: 1.5 }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 600, color: "#1c614d" }}
                >
                  {Labels?.appliedJobs}
                </Typography>
                <Typography variant="body1" sx={{ color: "#1c614d" }}>
                  {Labels?.Total} : {appliedjobcount}
                </Typography>
              </Stack>
            </Paper>
          </Grid>

          {/* Sorting buttons */}
          <Grid item xs={12} sx={{ maxHeight: "85vh", overflowY: "auto" }}>
            <Paper
              sx={{
                borderRadius: 2,
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent="flex-end"
                alignItems="center"
                sx={{ p: 1.5 }}
              >
                <Button
                  sx={{
                    m: 1,
                    backgroundColor: "#1c614d",
                    "&:hover": {
                      backgroundColor: "#1c614d",
                    },
                    "&:active": {
                      backgroundColor: "#1c614d",
                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    setsortdirection("DESC");
                  }}
                  disabled={sortdirection == "DESC"}
                >
                  {Labels.ChronicleOrderbyDueDateAsc}
                </Button>
                <Button
                  sx={{
                    m: 1,
                    backgroundColor: "#1c614d",
                    "&:hover": {
                      backgroundColor: "#1c614d",
                    },
                    "&:active": {
                      backgroundColor: "#1c614d",
                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    setsortdirection("ASC");
                  }}
                  disabled={sortdirection == "ASC"}
                >
                  {Labels.ChronicleOrderbyDueDateDesc}
                </Button>
              </Stack>
            </Paper>
          </Grid>

          {/* Applied and Deleted job listing */}
          <Grid item xs={12}>
            <Accordion sx={{ margin: "10px auto" }} defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 600, color: "#1c614d" }}
                >
                  {Labels.viewAll} {Labels.appliedJobs} ({appliedJobs.length})
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      {appliedJobs.length ? (
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              borderBottom: "2px solid black",
                              fontSize: "20px",
                            }}
                          >
                            {Labels.SNo}{" "}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              borderBottom: "2px solid black",
                              fontSize: "20px",
                            }}
                          >
                            {Labels.Applied}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              borderBottom: "2px solid black",
                              fontSize: "20px",
                            }}
                          >
                            {Labels.From}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              borderBottom: "2px solid black",
                              fontSize: "20px",
                            }}
                          >
                            {Labels.Ends}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              borderBottom: "2px solid black",
                              fontSize: "20px",
                            }}
                          >
                            {Labels.selecappr}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              borderBottom: "2px solid black",
                              fontSize: "20px",
                            }}
                          >
                            {Labels.Due}{" "}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              borderBottom: "2px solid black",
                              fontSize: "20px",
                            }}
                          >
                            {Labels.Company}{" "}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              borderBottom: "2px solid black",
                              fontSize: "20px",
                            }}
                          >
                            {Labels.JobPosition}{" "}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              borderBottom: "2px solid black",
                              fontSize: "20px",
                            }}
                          >
                            {Labels.delete}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              borderBottom: "2px solid black",
                              fontSize: "20px",
                            }}
                          >
                            {Labels.View}
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </TableHead>
                    <TableBody>
                      {appliedJobs.length ? (
                        appliedJobs?.map((row, index) => (
                          <TableRow key={row.eja_id}>
                            <TableCell
                              sx={{
                                fontSize: "17px",
                              }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "17px",
                              }}
                            >
                              {row?.jobAppliedOn}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "17px",
                              }}
                            >
                              {row?.jobCreatedOn}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "17px",
                              }}
                            >
                              {row?.jobLastDate}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "17px",
                              }}
                            >
                              {row?.jobApproximate_date}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "17px",
                                color:
                                  row?.due_days_withSign > 0 ? "green" : "red",
                              }}
                            >
                              {row?.due_days_withSign}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "17px",
                              }}
                            >
                              {row?.employer}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "17px",
                              }}
                            >
                              {row?.jobName}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "17px",
                              }}
                            >
                              <Checkbox
                                checked={
                                  selectedJobs.includes(row.eja_id) ||
                                  row.jobAppliedDeletestatus != 0
                                }
                                onChange={(e) =>
                                  handleCheckboxChange(e, row.eja_id)
                                }
                                disabled={row.jobAppliedDeletestatus != 0}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "17px",
                              }}
                            >
                              <Button
                                variant="contained"
                                sx={{
                                  backgroundColor: "#1c614d",
                                  "&:hover": {
                                    backgroundColor: "#1c614d",
                                  },
                                  "&:active": {
                                    backgroundColor: "#1c614d",
                                  },
                                }}
                                onClick={() => {
                                  redirect(`/detailedJobView/${row.eja_id}`);
                                }}
                              >
                                {Labels.View}
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <h3 style={{ textAlign: "center", width: "100%" }}>
                            {Labels?.NoJobsApplied}
                          </h3>
                        </TableRow>
                      )}
                      {appliedJobs.length ? (
                        <TableRow>
                          <TableCell> </TableCell>
                          <TableCell> </TableCell>
                          <TableCell> </TableCell>
                          <TableCell> </TableCell>
                          <TableCell> </TableCell>
                          <TableCell> </TableCell>
                          <TableCell> </TableCell>
                          <TableCell> </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={handleDeleteSelected}
                              disabled={selectedJobs.length === 0}
                            >
                              {Labels.delete}
                            </Button>
                          </TableCell>
                          <TableCell> </TableCell>
                        </TableRow>
                      ) : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
            <Accordion sx={{ margin: "10px auto" }} defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 600, color: "#1c614d" }}
                >
                  {Labels.viewAll} {Labels.deletedJobs} ({deletedJobs?.length})
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      {deletedJobs.length ? (
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              borderBottom: "2px solid black",
                              fontSize: "20px",
                            }}
                          >
                            {Labels.SNo}{" "}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              borderBottom: "2px solid black",
                              fontSize: "20px",
                            }}
                          >
                            {Labels.Applied}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              borderBottom: "2px solid black",
                              fontSize: "20px",
                            }}
                          >
                            {Labels.From}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              borderBottom: "2px solid black",
                              fontSize: "20px",
                            }}
                          >
                            {Labels.Ends}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              borderBottom: "2px solid black",
                              fontSize: "20px",
                            }}
                          >
                            {Labels.selecappr}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              borderBottom: "2px solid black",
                              fontSize: "20px",
                            }}
                          >
                            {Labels.Due}{" "}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              borderBottom: "2px solid black",
                              fontSize: "20px",
                            }}
                          >
                            {Labels.Company}{" "}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              borderBottom: "2px solid black",
                              fontSize: "20px",
                            }}
                          >
                            {Labels.JobPosition}{" "}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              borderBottom: "2px solid black",
                              fontSize: "20px",
                               visibility:"hidden"
                            }}
                          >
                            {Labels.delete}
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              borderBottom: "2px solid black",
                              fontSize: "20px",
                            }}
                          >
                            {Labels.View}
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </TableHead>
                    <TableBody>
                      {deletedJobs.length ? (
                        deletedJobs?.map((row, index) => (
                          <TableRow key={row.eja_id}>
                            <TableCell
                              sx={{
                                fontSize: "17px",
                              }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "17px",
                              }}
                            >
                              {row?.jobAppliedOn}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "17px",
                              }}
                            >
                              {row?.jobCreatedOn}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "17px",
                              }}
                            >
                              {row?.jobLastDate}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "17px",
                              }}
                            >
                              {row?.jobApproximate_date}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "17px",
                                color:
                                  row?.due_days_withSign > 0 ? "green" : "red",
                              }}
                            >
                              {row?.due_days_withSign}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "17px",
                              }}
                            >
                              {row?.employer}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "17px",
                              }}
                            >
                              {row?.jobName}
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "17px",
                                visibility:"hidden"
                              }}
                            >
                              <Checkbox
                                checked={
                                  selectedJobs.includes(row.eja_id) ||
                                  row.jobAppliedDeletestatus != 0
                                }
                                onChange={(e) =>
                                  handleCheckboxChange(e, row.eja_id)
                                }
                                disabled={row.jobAppliedDeletestatus != 0}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                fontSize: "17px",
                              }}
                            >
                              <Button
                                variant="contained"
                                sx={{
                                  backgroundColor: "#1c614d",
                                  "&:hover": {
                                    backgroundColor: "#1c614d",
                                  },
                                  "&:active": {
                                    backgroundColor: "#1c614d",
                                  },
                                }}
                                onClick={() => {
                                  redirect(`/detailedJobView/${row.eja_id}`);
                                }}
                              >
                                {Labels.View}
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <h3 style={{ textAlign: "center", width: "100%" }}>
                          {Labels?.NoDeletedJobs}
                          </h3>
                        </TableRow>
                      )}
                     
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
        
          </Grid>
        </>
      )}
    </Grid>
  );
}


// {Labels?.NoDeletedJobs}