import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Joboffers1 from "../../Images/Joboffers1.png";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Paper,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { axiosAPI } from "../Axios/Axios";
import { PUBLIC_IMAGE_FOLDER } from "../Axios/Constant";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const JobOffers = () => {
  const redirect = useNavigate();
  const [jobVaccancy, setjobVaccancy] = useState([]);
  const [jobcount, setJobCount] = useState(0);
  const [categoryCount, setCategoryCount] = useState(0);
  const Labels = useSelector((state) => state.allLabels?.labels);
  const allList = useSelector((state) => state.allList.list);

  const setJobOffers = () => {
    setjobVaccancy(allList?.jobs?.jobs);
    setCategoryCount(allList?.jobs?.categorycount);
    setJobCount(allList?.jobs?.total);
  };
  useEffect(() => {
    setJobOffers();
  }, []);

  const searchJobs = (e, data) => {
    redirect(`/jobSearchPrelogin/${data.id}`);
  };
  return (
    <Stack sx={{ mb: { xs: 6, sm: 12 } }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Typography
            variant="h3"
            sx={{
              color: "#BA8C04",
              paddingLeft: { xs: 3, lg: 6 },
              fontWeight: 600,
              fontStyle: "normal",
              fontSize: {
                xs: "30px",
                lg: "50px",
              },
            }}
          >
            {Labels?.Job}
          </Typography>
          <Paper
            sx={{
              backgroundColor: "#1C614E",
              height: "50%",
              paddingLeft: { xs: 0, sm: 3, lg: 6 },
              borderRadius: {
                xs: "15px 15px 15px 15px",
                md: "15px 0px 0px 15px",
              },
            }}
          >
            <Typography
              variant="h3"
              sx={{
                color: "#FFFFFF",
                fontWeight: 600,
                fontSize: {
                  xs: "30px",
                  lg: "50px",
                },
                fontStyle: "normal",
                mb: 2,
                paddingLeft: { xs: 3, sm: 0 },
              }}
            >
              {Labels?.offers}
            </Typography>
            <Stack
              direction={{xs:'column', sm:"row"}}
              spacing={1}
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                width: { xs: "100%", sm: "100%" },
                color: "#fff",
                mb: 3,
                paddingLeft: { xs: 3, sm: 0 },
              }}
            >
              <div style={{ marginRight: "2rem" }}>
                <p
                  style={{
                    fontSize: 35,
                    margin: "1px auto",
                    fontWeight: 300,
                  }}
                >
                  {jobcount} {Labels?.offers}
                </p>
              </div>
              <div
                style={{
                  borderRadius: 100,
                  width: 35,
                  height: 35,
                  background: "#fff",
                  color: "#000",
                  padding: 10,
                  lineHeight: 1,
                  marginRight: "2rem",
                }}
              >
                {Labels?.in}
              </div>
              <div>
                <p
                  style={{
                    fontSize: 35,
                    margin: "1px auto",
                    fontWeight: 300,
                  }}
                >
                  {categoryCount} {Labels?.categories}
                </p>
              </div>
            </Stack>
            <Box>
              <Grid
                container
                spacing={2}
                sx={{ flexGrow: 1, paddingRight: { xs: 0, sm: 3, lg: 6 } }}
              >
                {allList?.jobs?.jobs &&
                  allList?.jobs?.jobs.slice(0, 2).map((val, index) => (
                    <Grid
                      key={index}
                      item
                      xs={12}
                      sm={6}
                      md={5.2}
                      sx={{ width: { xs: "100%", xl: "100%" } }}
                    >
                      <Card
                        sx={{
                          borderRadius: "17px",
                          boxShadow: "0px 4px 39px rgba(0, 0, 0, 0.1)",
                          cursor: "pointer",
                          height:"100%",
                        }}
                        onClick={(e) => searchJobs(e, val)}
                      >
                        <CardContent>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "initial",
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              src={`${PUBLIC_IMAGE_FOLDER}${val.categoryIcon}`}
                              sx={{
                                width: "40px",
                                height: "40px",
                              }}
                            />

                            <Typography
                              sx={{
                                color: "rgba(0, 0, 0, 0.68)",
                                fontSize: 14,
                                margin: "5px",
                              }}
                            >
                              {val.category}
                            </Typography>
                          </div>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              color: "#000000",
                              textTransform: "capitalize",
                              height: "32px",
                              overflow: "hidden",
                            }}
                          >
                            {val?.jobName.length > 20
                              ? val?.jobName.slice(0, 20) + "..."
                              : val?.jobName}
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              margin: "10px 0",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "initial",
                                alignItems: "center",
                              }}
                            >
                              <PlaceOutlinedIcon sx={{ color: "#4CAF50" }} />

                              <Typography
                                sx={{
                                  color: "#2B4947",
                                  fontSize: {
                                    xs: "11px",
                                    lg: "13px",
                                  },
                                  fontWeight: 500,
                                }}
                              >
                                {val?.address}
                              </Typography>
                            </div>
                          </div>
                        </CardContent>
                        <CardActions
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            borderTop: "1px solid rgba(0, 0, 0, 0.07)",
                            padding: 2,
                          }}
                        >
                          <div>
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: "13px",
                                color: "#2B4947",
                                mb: 1,
                              }}
                            >
                              {val.addedOn}
                            </Typography>
                            <Typography
                              sx={{
                                fontWeight: 600,
                                fontSize: "13px",
                                color: "#000000",
                              }}
                            >
                              {val?.companyName.length > 20
                                ? val?.companyName.slice(0, 20) + "..."
                                : val?.companyName}
                            </Typography>
                          </div>
                          <div>
                            <Avatar
                              src={`${val.companyLogo}`}
                              sx={{
                                width: "30px",
                                height: "30px",
                              }}
                            />
                          </div>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                <Grid item sx={{ display: "none" }}></Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            paddingLeft: "0px !important",
            marginTop: { md: "35px", lg: "58px" },
            display: { xs: "none", sm: "none", md: "block" },
          }}
        >
          <img src={Joboffers1} alt="joboffer" className="joboffers-img" />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          width: { md: "100%", lg: "95%", xl: "85%" },
          marginTop: { xs: "3%", sm: "3%", md: "-1%", lg: "-6%", xl: "-12%" },
          paddingLeft: { xs: 0, md: 4, lg: 6 },
          paddingRight: { xs: 0, md: 4, lg: 6 },
        }}
      >
        {allList?.jobs?.jobs &&
          allList?.jobs?.jobs.slice(2, 5).map((val, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              md={4}
              sx={{ width: { xs: "95%", lg: "100%" } }}
            >
              <Card
                sx={{
                  borderRadius: "17px",
                  boxShadow: "0px 4px 39px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
                }}
                onClick={(e) => searchJobs(e, val)}
              >
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "initial",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      src={`${PUBLIC_IMAGE_FOLDER}${val.categoryIcon}`}
                      sx={{
                        maxHeight: "40px",
                        maxWidth: "40px",
                        padding: "5px",
                        marginRight: "5px",
                        background: "rgba(186, 140, 4, 0.14)",
                        borderRadius: "50%",
                      }}
                    />
                    <Typography
                      sx={{
                        color: "rgba(0, 0, 0, 0.68)",
                        fontSize: 14,
                        margin: "5px",
                      }}
                    >
                      {val.category}
                    </Typography>
                  </div>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "#000000",
                      textTransform: "capitalize",
                    }}
                  >
                    {val.jobName}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      margin: "10px 0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "initial",
                        alignItems: "center",
                      }}
                    >
                      <PlaceOutlinedIcon sx={{ color: "#4CAF50" }} />

                      <Typography
                        sx={{
                          color: "#2B4947",
                          fontSize: {
                            xs: "11px",
                            lg: "13px",
                          },
                          fontWeight: 500,
                        }}
                      >
                        {val.city}
                      </Typography>
                    </div>
                    {/* <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "13px",
                        color: "#2B4947",
                      }}
                    >
                      full time
                    </Typography> */}
                  </div>
                </CardContent>
                <CardActions
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderTop: "1px solid rgba(0, 0, 0, 0.07)",
                    padding: 2,
                  }}
                >
                  <div>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "13px",
                        color: "#2B4947",
                        mb: 1,
                      }}
                    >
                      {val.addedOn}
                    </Typography>
                    {val?.companyName && (
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: "13px",
                          color: "#000000",
                        }}
                      >
                        {val?.companyName.length > 20
                          ? val?.companyName.slice(0, 20) + "..."
                          : val?.companyName}
                      </Typography>
                    )}
                  </div>
                  <div>
                    <Avatar
                      src={`${val.companyLogo}`}
                      sx={{
                        maxHeight: "30px",
                        maxWidth: "30px",
                      }}
                    />
                  </div>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Stack>
  );
};

export default JobOffers;
