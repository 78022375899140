import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import {profanityfn}  from "../Profanity/Profanity";

export default function YesNo({ value, setValue, placeHolder, style }) {
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  const handleChange = (event) => {
    setValue(profanityfn(ProfanityList,event.target.value));
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  const usertype = localStorage.getItem("userType");
  return (
    <Fragment>
      <FormControl sx={{ width: "100%" }}>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={value}
          onChange={handleChange}
          fullWidth
          // readOnly={usertype === "Recruiter" ? true : false}
          displayEmpty
          variant="outlined"
          sx={{ padding: "10px 12px",fontSize:"14px",  fontFamily: "Poppins", ...style }}
        >
          <MenuItem disabled value="">
            <span style={{ color: "#bcbcbc" }}>{placeHolder}</span>
          </MenuItem>
          <MenuItem value="1">{Labels?.yes}</MenuItem>
          <MenuItem value="0">{Labels?.No}</MenuItem>
        </Select>
      </FormControl>
    </Fragment>
  );
}
