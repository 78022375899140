import React, { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import "./Style.css";
import jobcompany1 from "../../Images/jobcompany1.png";
import jobcompany2 from "../../Images/jobcompany2.png";
import {
  Box,
  Card,
  CardContent,
  Stack,
  Paper,
  Typography,
  CardActions,
  Avatar,
} from "@mui/material";
import { axiosAPI } from "../Axios/Axios";
import { PUBLIC_IMAGE_FOLDER } from "../Axios/Constant";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { useSelector } from "react-redux";

const JobCompanies = () => {
  const redirect = useNavigate();
  const Labels = useSelector((state) => state.allLabels.labels);
  const allList = useSelector((state) => state.allList.list);
  const [companies, setCompanies] = useState([]);
  const searchJobs = (e, data) => {
    e.preventDefault();
    const searchData = {
      language: "en",
      search: data.name,
      companyID: data.id,
    };
    redirect("/jobSearchPrelogin", { state: searchData });
  };
  
  const getCompaniesList = async () => {
    try {
      const result = await axiosAPI.get("/getCompaniesList");
      const { data, status } = result.data;
      if (status) {
        setCompanies(data);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };
  useEffect(() => {
    getCompaniesList();
  }, []);

  const handlecompanynameClick = (employerId) => {
    redirect(`/company/${employerId}`)
    //// console.log(`Employer ID clicked: ${employerId}`);
  };
  const viewCompanyDetails = (companyId) => {
    redirect(`/company/${companyId}`)
  }
  return (
    <Stack sx={{ mb: { xs: "2rem", sm: "8rem" } }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Typography
            sx={{
              paddingBottom: 0,
              fontWeight: 600,
              fontSize: {
                xs: "30px",
                lg: "40px",
                xl: "50px",
              },
              color: "#BA8C04",
              paddingLeft: { xs: 3, lg: 6 },
            }}
          >
            <Box
              component="span"
              sx={{ color: "#2B4947", paddingRight: "10px" }}
            >
              {Labels?.Find}
            </Box>
            {Labels?.Best}
          </Typography>
          <Paper
            sx={{
              height: { xs: 200, sm: 200, md: 600, lg: 720, xl: 720 },
              backgroundColor: "#1C614E",
              width: { sm: "100%", md: "65%" },
              borderRadius: "10px",
              marginBottom: { xs: -5 },
            }}
          >
            <Typography
              sx={{
                paddingLeft: { xs: 3, lg: 6 },
                paddingBottom: 0,
                marginTop: 0,
                paddingTop: 0,
                fontWeight: 600,
                fontSize: {
                  xs: "30px",
                  lg: "40px",
                  xl: "50px",
                },
                color: "#FFFFFF",
                mb: 1,
              }}
            >
              {Labels?.Companies}
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
            >
              <Typography
                sx={{
                  paddingLeft: { xs: 3, lg: 6 },
                  fontSize: "17px",
                  color: "#FFFFFF",
                  cursor: "pointer",
                  zIndex:"99999"
                }}
                onClick={() => {redirect("/allCompanies")}}
              >
                {Labels?.Allcompanies}
              </Typography>
              <ArrowForwardIcon
                sx={{
                  fontSize: "20px",
                  color: "#FFFFFF",
                  cursor: "pointer",
                }}
                
              />
            </Stack>

            <Box sx={{ position: "relative", paddingLeft: { xs: 3, md: 0 }}}>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <img
                  src={jobcompany1}
                  alt="Job Company 1"
                  className="jobCompanies-img1"
                />
              </Box>
              <Box sx={{ display: { xs: "none", lg: "block" } }}>
                <img
                  src={jobcompany2}
                  alt="Job Company 2"
                  height="265px"
                  className="jobCompanies-img2"
                />
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={7}>
          <Stack
            direction="column"
            justifyContent="center"
            height="100%"
            mt={{ xs: -6, md: 5, lg: 4 }}
          >
            <Grid container spacing={6}>
              {allList?.companies &&
                allList?.companies.slice(0, 4).map((val, i) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{
                      cursor: "pointer",
                      mt: 4,
                    }}
                    key={i}
                  >
                    <Card
                      sx={{
                        borderRadius: "17px",
                        boxShadow: "0px 4px 39px rgba(0, 0, 0, 0.1)",
                        cursor: "pointer",
                      }}
                      onClick={() => viewCompanyDetails(val?.id)}
                    >
                      <CardContent>
                        <Avatar
                          src={`${val.logo}`}
                          sx={{
                            width: 65,
                            height: 65,
                            marginTop: "-55px",
                            position: "absolute",
                            marginLeft: "10px",
                            borderRadius: "50%",
                          }}
                        />
                        <Typography
                          sx={{
                            paddingLeft: 1,
                            paddingTop: 3,
                            color: "#000000",
                            fontWeight: 600,
                            fontSize: {
                              xs: "20px",
                              lg: "23px",
                            },
                            lineHeight: "34px",
                            textTransform: "capitalize",
                            height: "60px",
                            overflow: "hidden",
                          }}
                        >
                          {val?.name.length > 17
                            ? val.name.slice(0, 17) + "..."
                            : val?.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            paddingLeft: 1,
                            paddingTop: 1,
                            color: "rgba(113, 109, 109, 0.85)",
                            paddingBottom: 3,
                            overflowY: "hidden",
                            minHeight: "77px",
                            maxHeight: "77px",
                            lineHeight: "23px",
                          }}
                        >
                          {val.description?.length > 100
                            ? val.description?.slice(0, 100) + "..."
                            : val.description}
                        </Typography>
                      </CardContent>
                      <CardActions
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          borderTop: "1px solid rgba(0, 0, 0, 0.07)",
                          padding: 2,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <PlaceOutlinedIcon sx={{ color: "2B4947" }} />
                          <Typography
                            sx={{
                              color: "#2B4947",
                              fontSize: {
                                xs: "11px",
                                lg: "13px",
                              },
                              fontWeight: 500,
                              fontStyle: "normal",
                            }}
                          >
                            {val.state}
                          </Typography>
                        </div>
                        <Typography
                          sx={{
                            paddingLeft: 5,
                            color: "#BA8C04",

                            fontSize: {
                              xs: "11px",
                              sm: "12px",
                              lg: "13px",
                            },
                          }}
                        >
                          {val.jobs} {Labels?.Positionavailable}
                        </Typography>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default JobCompanies;
