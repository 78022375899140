import React, { useEffect, useState } from "react";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import CreateCompany from "../Employer/Companies/createCompany";
import { profanityfn } from "../Profanity/Profanity";

export default function AddedCompanies({ style, value, setValue, setView }) {
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);
  const Labels = useSelector((state) => state.allLabels.labels);

  const [allCompanies, setAllCompanies] = useState([]);
  const [isCreateCompany, setIsCreateCompany] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const getAllCompanies1 = async () => {
    const result = await axiosAPI.post("/companiesList");
    const { status, data } = await result.data;

    if (status === true) {
      setAllCompanies(data);
      if (value === "others") {
        setValue(data.at(-1).consultancy_id);
      } else {
        setValue("");
      }
    } else {
      setAllCompanies([]);
    }
  };

  useEffect(() => {
    getAllCompanies1();
  }, []);

  const handleChange = (event, newValue) => {
    if (newValue?.name === "others") {
      handleOthersClick();
    } else if (newValue?.consultancy_id) {
      setValue(profanityfn(ProfanityList, newValue.consultancy_id));
    } else {
      setValue(""); // Clear value if no valid consultancy_id
    }
  };

  const handleOthersClick = () => {
    setIsCreateCompany(true);
    setView(false);
  };

  const handleCloseModal = () => {
    setIsCreateCompany(false);
    setView(true);
  };

  const filteredCompanies = allCompanies.filter((company) => {
    const searchFields = [
      company?.name,
      company?.address,
      company?.state,
      company?.county,
    ];

    if (!searchKeyword) {
      return true;
    }

    return searchFields.some((field) =>
      field?.toLowerCase().includes(searchKeyword.toLowerCase())
    );
  });

  const getOptionLabel = (option) => {
    const { name, address, state, county } = option;
    const details = [address, state, county].filter(Boolean).join(", ");
    return details ? `${name} (${details})` : name;
  };

  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <>
      <Grid item xs={12}>
        <label>{Labels?.companyName}</label>
        <span style={{ color: "red" }}>*</span>

        <Autocomplete
          open={isOpen} // Controls whether the dropdown is open
          onOpen={() => {
            // Only allow opening if the value is null (i.e., no company selected)
            if (!value) {
              setIsOpen(true);
            }
          }}
          onClose={() => {
            setIsOpen(false);
          }}
          options={filteredCompanies}
          getOptionLabel={getOptionLabel}
          onChange={(event, newValue) => {
            handleChange(event, newValue);
            // Close the dropdown if a company is selected
            if (newValue) {
              setIsOpen(false);
            }
          }}
          onInputChange={(event, newInputValue) => {
            setSearchKeyword(newInputValue);
          }}
          value={
            allCompanies.find((company) => company.consultancy_id === value) ||
            null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={Labels.search}
              InputLabelProps={{
                style: { color: "black"},
              }}
              sx={{ width: "100%", marginBottom: "10px" }}
              onClick={() => {
                // If a value exists, prevent opening unless it's removed
                if (!value) {
                  setIsOpen(true);
                }
              }}
            />
          )}
          PaperComponent={({ children }) => (
            <div style={{ maxHeight: 300, overflowY: "auto", background:"white", border:"1px solid grey", borderRadius:"5px"}}>{children}</div>
          )}
          noOptionsText={
            allCompanies.length === 0 ? Labels.Nocompaniesfound : null
          }
          filterOptions={(options, state) => {
            const filtered = options.filter(
              (option) =>
                option.name
                  .toLowerCase()
                  .includes(state.inputValue.toLowerCase()) ||
                option.address
                  .toLowerCase()
                  .includes(state.inputValue.toLowerCase()) ||
                option.state
                  .toLowerCase()
                  .includes(state.inputValue.toLowerCase()) ||
                option.county
                  .toLowerCase()
                  .includes(state.inputValue.toLowerCase())
            );
            if (allCompanies.length === 0) {
              filtered.push({ name: Labels?.others, consultancy_id: "others" });
            }
            return filtered;
          }}
          isOptionEqualToValue={(option, value) =>
            option.consultancy_id === value.consultancy_id
          }
        />
      </Grid>

      <CreateCompany
        open={isCreateCompany}
        handleClose={handleCloseModal}
        getAllCompanies={getAllCompanies1}
      />
    </>
  );
}
