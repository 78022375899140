import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
  Container,
  Grid,
  Switch,
  Chip,
  Skeleton,
  LinearProgress,
  styled,
  linearProgressClasses,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TransgenderIcon from "@mui/icons-material/Transgender";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import CustomButton from "../../Components/CustomButton";
import WorkIcon from "@mui/icons-material/Work";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import ImportantDevicesIcon from "@mui/icons-material/ImportantDevices";
import PieChartIcon from "@mui/icons-material/PieChart";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import JobSeekerProfileSideview from "./JobSeekerProfileSideview";
import { useState } from "react";
import EploymentModal from "./EploymentModal";
import { axiosAPI, axiosupload } from "../../Axios/Axios";
import {
  errorNotify,
  isValidEmail,
  succesNotify,
  validatePhoneNumber,
  warningNotify,
} from "../../CommonCode/Commonfunc";
import { useEffect } from "react";
import Gender from "../../CommonCode/Gender";
import Designation from "../../CommonCode/Designation";
import DesignationProfile from "../../CommonCode/DesignationProfile";
import EducationModal from "./EducationModal";
import SkillsaddModal from "./SkillsaddModal";
import CoverLetterModal from "./CoverLetterModal";
import EmployementUpdateModal from "./EmployementUpdateModal";
import EducationUpdateModal from "./EducationUpdateModal";
import PreferedLocationModal from "./PreferedLocationModal";
import JobSeekerNavbar from "./JobSeekerNavbar";
import { useStyles } from "../../CommonCode/Commonfunc";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import { format, subYears } from "date-fns";
import PublicIcon from "@mui/icons-material/Public";
import BungalowIcon from "@mui/icons-material/Bungalow";
import HomeIcon from "@mui/icons-material/Home";
import Country from "../../CommonCode/Country";
import State from "../../CommonCode/State";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EmploymentDeleteAlert from "./JobseekerDashboard/EmploymentDeleteAlert";
import IconLabelTextField from "../../Components/iconLabelText";
import "./style.css";
import ExperienceLevel from "../../CommonCode/ExperienceLevel";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import EmployerPasswordChangeModal from "../../Employer/EmployerProfile/EmployerPasswordChangeModal";
import SimpleBackdrop from "../../CommonCode/SimpleBackdrop";
import LanguageLevel from "../../CommonCode/LanguageLevel";
import LanguageAddModal from "./LanguageAddModal";
import LanguageEditModal from "./LanguageEditModal";
import CircleIcon from "@mui/icons-material/Circle";
import { updateLoadingState } from "../../../redux/Loader";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import SkillsNew from "../../CommonCode/SkillsNew";
import { profanityfn, profanitySend } from "../../Profanity/Profanity";
import IconLabelTextMUI from "../../Components/iconLabelTextMUI";
import InputWithLabel from "../../Components/InputWithLabel";
import Currency from "../../CommonCode/Currency";
import SalaryPeriod from "../../CommonCode/SalaryPeriod";
import SelectJobType from "../../CommonCode/SelectJobType";
import SelectJobTypeWithList from "../../CommonCode/SelectJobTypeWithList ";
import ContractTypeAddModal from "./ContractTypeAddModal";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: `white`,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1C614E",
  },
}));

const JobseekerProfile = ({ routeChange }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isButton1Disable, setButton1Disable] = useState(false);
  const [isButton2Disable, setButton2Disable] = useState(false);

  const [employments, setEmployments] = useState([]);
  const [educations, seteducations] = useState([]);

  const [countrylist, setcountrylist] = useState([]);

  const getCountryNameById = (countryId) => {
    const country = countrylist.find((item) => item.id == countryId);
    return country ? country.name : ""; // Fallback if not found
  };

  useEffect(() => {
    const getCountries = async () => {
      const result = await axiosAPI.get("/getCountries");
      const { success, data } = await result.data;
      setcountrylist(data);
    };
    getCountries();
  }, [educations]);

  const [skillsId, setSkillsId] = useState([]);
  const [contracttypeid, setcontracttypeid] = useState([]);
  const [preferedLocations, setPreferedLocations] = useState([]);
  const [skillsName, setskillsName] = useState([]);
  const [contractname, setContractname] = useState([]);
  const [Languages, setLanguages] = useState([]);
  const [userGender, setUserGender] = useState("");
  const [userDesignation, setUserDesignation] = useState("");
  const [userExperienceLevel, setUserExperienceLevel] = useState("");
  const [countryId, setCountryId] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [coverLetterData, setCoverLetterData] = useState("");
  const [resume, setResume] = useState("");
  const [jsresume, uploadjsresume] = useState(null);
  const [updateEmployment, setUpdateEmployment] = useState({});
  const [updatedEducation, setUpdatedEducation] = useState({});
  const [isApiCalled, setIsApiCalled] = useState(0);
  const [deleteEmploymentId, setDeleteEmploymentId] = useState("");
  const [deleteEducationId, setDeleteEducationId] = useState("");
  const [deleteLanguageId, setDeleteLanguageId] = useState("");
  const [Ischecked, setIsChecked] = useState(false);

  const [currency, setcurrency] = useState("");
  const [salaryPeriod, setsalaryPeriod] = useState("");

  const [currency2, setcurrency2] = useState("");
  const [salaryPeriod2, setsalaryPeriod2] = useState("");

  const [selectedJobTypeIds, setSelectedJobTypeIds] = useState([]); // State to hold the selected job types

  const currentDate = new Date();
  const maxDate = subYears(currentDate, 50);
  const maxDateFormatted = format(maxDate, "yyyy-MM-dd");
  const [languageData, setLanguageData] = useState({});

  // ------------Modal Contro States---------------

  const [open, setOpen] = useState(false);
  const [skillsopen, setSkillOpen] = useState(false);
  const [ContractModalopen, setContractModalopen] = useState(false);

  const [openPreferedLocationsModal, setPreferedLocationsModal] =
    useState(false);
  const [openCoverLetter, setopenCoverLetter] = useState(false);
  const [openEducationAddModel, setopenEducationModel] = useState(false);
  const [openPasswordReset, setopenPasswordReset] = useState(false);
  const [openEmploymentUpdateModal, setemploymentUpdateModal] = useState(false);
  const [openEducationUpdateModal, setEducationUpdateModal] = useState(false);
  const [openEmploymentDeleteAlert, setEmploymentDeleteAlert] = useState(false);

  const tokens = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  const resettedByAdmin = localStorage.getItem("resettedByAdmin");
  const fileInputRef = useRef(null);

  const handleSelectedJobTypes = (selectedTypes) => {
    setSelectedJobTypeIds(selectedTypes); // Update state in parent
  };

  const handleAttachFile = () => {
    fileInputRef.current.click();
  };

  // ----------Loading status from redux----------------------

  const isLoading = useSelector((state) => state.Loading.isLoading);

  // ----------Modal Controls----------------------

  const openEmploymentModel = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsApiCalled(Math.random());
  };
  const openEducationModel = () => {
    setopenEducationModel(true);
  };
  const handleCloseEducationModel = () => {
    setopenEducationModel(false);
    setIsApiCalled(Math.random());
  };
  const openCoverLetterModel = () => {
    setopenCoverLetter(true);
  };
  const handleCloseCoverLetter = () => {
    setopenCoverLetter(false);
    setIsApiCalled(Math.random());
  };
  const OpenSkillModal = () => {
    setSkillOpen(true);
  };
  const OpenContractTypeModal = () => {
    setContractModalopen(true);
  };
  const skillCloseModal = () => {
    setSkillOpen(false);
    setIsApiCalled(Math.random());
  };
  const ContractTypeModalClose = () => {
    setContractModalopen(false);
    setIsApiCalled(Math.random());
  };
  const employemtUpdateModalClose = () => {
    setemploymentUpdateModal(false);
    setIsApiCalled(Math.random());
  };
  const educationUpdateModalClose = () => {
    setEducationUpdateModal(false);
    setIsApiCalled(Math.random());
  };
  const openPasswordResetModal = () => {
    setopenPasswordReset(true);
  };
  const ForgotPasswordModelClose = () => {
    setopenPasswordReset(false);
    setIsApiCalled(Math.random());
  };
  const closePreferedLocationsModal = () => {
    setPreferedLocationsModal(false);
    setIsApiCalled(Math.random());
  };
  const closeEmploymentDeleteModal = () => {
    setEmploymentDeleteAlert(false);
    setDeleteEmploymentId("");
    setDeleteEducationId("");
    setIsApiCalled(Math.random());
  };

  const handleToggle = () => {
    setIsChecked((prevChecked) => {
      sendValueToAPI(!prevChecked);
      return !prevChecked;
    });
  };

  const sendValueToAPI = (value) => {
    axiosAPI
      .post("/enableDisableTFA", { twoFactorPreferred: value ? 1 : 0 }, tokens)
      .then((res) => {})
      .catch((err) => {
        //// console.log(err);
      });
  };

  //---------------Resume Upload Api-------------
  const getResume = (e) => {
    uploadjsresume(e.target.files[0]);
    setResume(e.target.files[0].name);
  };
  const uploadResume = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("files", jsresume);
    data.append("language", localStorage.getItem("language"));
    if (!jsresume) {
      errorNotify(Labels.uploadFile);
    } else if (jsresume.type !== "application/pdf") {
      errorNotify(Labels.fileValidation);
      return;
    } else {
      const result = await axiosupload.post("/uploadResume", data, tokens);
      const { status, message } = result.data;
      if (status === "successs") {
        succesNotify(message);
        setIsApiCalled(Math.random());
      } else {
        warningNotify(message);
      }
    }
  };

  // -------------Profile Detail GET API----------------------
  useEffect(() => {
    getProfile();
  }, [isApiCalled]);

  const uid = localStorage.getItem("uid");
  const postdata = {
    jobseekerid: uid,
  };

  const [address1, setAddress1] = useState("");
  const [city, setCity] = useState("");
  const [countryid, setCountryid] = useState("");
  const [coverLetter, setCoverLetter] = useState("");
  const [currentEmployer, setCurrentEmployer] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [emailid, setEmailId] = useState("");
  const [expectedSalary, setExpectedSalary] = useState("");
  const [currentSalary, setCurrentSalary] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [gender, setGender] = useState("");
  const [profilePicPath, setProfilePicPath] = useState("");
  const [stateid, setStateid] = useState("");
  const [totalExperience, setTotalExperience] = useState("");

  const getProfile = async () => {
    try {
      dispatch(updateLoadingState(true));
      const result = await axiosAPI.post("/profile", postdata);
      const { success, data, message } = result.data;
      if (success === true) {
        setAddress1(data?.address1);
        setCity(data?.city);
        setCountryid(data?.countryid);
        setStateid(data?.stateid);
        setCurrentEmployer(data?.currentEmployer);

        setDateOfBirth(data?.dateOfBirth);
        setDisplayName(data?.displayName);
        setEmailId(data?.emailid);

        setPhoneNo(data?.phoneNo);
        setGender(data?.gender);
        setProfilePicPath(data?.profilePicPath);
        setTotalExperience(data?.totalExperience);

        setIsChecked(data.twoFactorPreferred == 1 ? true : false);
        setCountryId(
          data.countryid ? { id: data.countryid, name: data.countryName } : null
        );
        setStateId(
          data.stateid ? { id: data.stateid, name: data.stateName } : null
        );
        setUserGender(data.gender ? data.gender : "");
        setUserDesignation(data.designation ? data.designation : "");
        setEmployments(data?.employment);
        setskillsName(data?.skills);
        setContractname(data?.contractTypes);
        seteducations(data?.education);
        setSkillsId(data?.skillids);
        setPreferedLocations(data?.locations);
        setCoverLetterData(data?.coverLetter);

        setResume(data?.resumePath);
        setUserExperienceLevel(
          data.experienceLevel ? data?.experienceLevel : ""
        );
        setLanguages(data.languages);

        setTimeout(() => {
          setCurrentSalary(JSON.parse(data?.currentSalary)?.currentSalary);
          setExpectedSalary(JSON.parse(data?.expectedSalary)?.expectedSalary);

          setcurrency(JSON.parse(data?.currentSalary)?.currency);
          setsalaryPeriod(JSON.parse(data?.currentSalary)?.salaryPeriod);
          setcurrency2(JSON.parse(data?.expectedSalary)?.currency);
          setsalaryPeriod2(JSON.parse(data?.expectedSalary)?.salaryPeriod);
        }, 2000);

        dispatch(updateLoadingState(false));
        routeChange(Math.random());
      } else {
        errorNotify(message);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  //------------updating  personal information---------------
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  //------------ function to check DOB Validation---------------
  function isDateBefore50YearsAgo(inputDateStr) {
    const inputDate = new Date(inputDateStr);
    const today = new Date();
    const fiftyYearsAgo = new Date();
    fiftyYearsAgo.setFullYear(today.getFullYear() - 50);
    return inputDate < fiftyYearsAgo;
  }

  const sendChatProfanity = (e) => {
    const postData = {
      displayName: displayName,
      dateOfBirth: dateOfBirth,
      gender: gender,
      emailid: emailid,
      phoneNo: phoneNo,
      city: city,
      countryid: countryid,
      stateid: stateid,
      address1: address1,
      language: localStorage.getItem("language"),
    };

    let profanityDetected;
    for (const key in postData) {
      if (profanitySend(ProfanityList, postData[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      submitPersonalInformation(e);
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };

  const submitPersonalInformation = async (e) => {
    e.preventDefault();
    setButton1Disable(true);
    let countryid = countryId?.id;
    let stateid = stateId?.id;
    let gender = userGender;
    const postData = {
      displayName: displayName,
      emailid: emailid,
      dateOfBirth: dateOfBirth,
      gender: gender,
      countryid: countryid,
      stateid: stateid,
      city: city,
      phoneNo: phoneNo,
      address1: address1,
      language: localStorage.getItem("language"),
    };
    if (!displayName) {
      errorNotify(Labels.nameRequired);
      setButton1Disable(false);
    } else if (displayName.length > 200) {
      errorNotify(Labels.nameValidation);
      setButton1Disable(false);
    } else if (!emailid) {
      errorNotify(Labels.emailValidation);
      setButton1Disable(false);
    } else if (!dateOfBirth) {
      errorNotify(Labels.DOBrequired);
      setButton1Disable(false);
    } else if (!countryId) {
      errorNotify(Labels.countryRequired);
      setButton1Disable(false);
    } else if (!stateId) {
      errorNotify(Labels.stateRequired);
      setButton1Disable(false);
    } else if (!city) {
      errorNotify(Labels.cityRequired);
      setButton1Disable(false);
    } else if (!address1) {
      errorNotify(Labels.AddressisRequired);
      setButton1Disable(false);
    } else {
      const isBefore50YearsAgo = isDateBefore50YearsAgo(dateOfBirth);
      if (!isBefore50YearsAgo) {
        setButton1Disable(false);
        return errorNotify(Labels.DOBvalidation);
      }

      const result = await axiosAPI.post("/updateUserProfile", postData);
      const { success, message } = result.data;
      if (success === true) {
        succesNotify(message);
        setIsApiCalled(Math.random());
        setButton1Disable(false);
      } else {
        errorNotify(message);
        setButton1Disable(false);
      }
    }
  };

  const submitCareerProfile = async (e) => {
    const CurrentSalary = {
      currentSalary: currentSalary,
      currency: currency,
      salaryPeriod: salaryPeriod,
    };
    const ExpectedSalary = {
      expectedSalary: expectedSalary,
      currency: currency2,
      salaryPeriod: salaryPeriod2,
    };

    setButton2Disable(true);
    const postcareerProfile = {
      designation: userDesignation,
      currentSalary: JSON.stringify(CurrentSalary),
      expectedSalary: JSON.stringify(ExpectedSalary),
      ContractType: JSON.stringify(selectedJobTypeIds),
      salaryPeriod: salaryPeriod,
      totalExperience: totalExperience,
      currentEmployer: currentEmployer,
      experienceLevel: userExperienceLevel,
      language: localStorage.getItem("language"),
    };
    e.preventDefault();
    // if (
    //   // !userDesignation ||
    //   // ___________________________________________________________________________________ Removed validation as per client req (Salary is not mandatory)
    //   // !currentSalary ||
    //   // !expectedSalary ||
    //   // !totalExperience ||
    //   // !currentEmployer
    // ) {
    //   setButton2Disable(false);
    //   return errorNotify(Labels.allFieldsMandatory);
    // } else if (isNaN(totalExperience)) {

    if (isNaN(totalExperience)) {
      setButton2Disable(false);
      return errorNotify(Labels.experienceValidation);
    } else {
      const result = await axiosAPI.post(
        "/updateUserProfile",
        postcareerProfile
      );
      const { success, message } = result.data;
      if (success === true) {
        succesNotify(message);
        setIsApiCalled(Math.random());
        setButton2Disable(false);
      } else {
        warningNotify(message);
        setButton2Disable(false);
      }
    }
  };
  //-------------- Update Employment ---------------------
  const EditEmployment = (data) => {
    setUpdateEmployment(data);
    setemploymentUpdateModal(true);
  };
  //-------------- Update Education ---------------------
  const updateEducation = (data) => {
    setUpdatedEducation(data);
    setEducationUpdateModal(true);
  };
  //------------Delete Employment---------------
  const deleteEmployment = (e, data) => {
    e.stopPropagation();
    setDeleteEmploymentId(data.jseh_id);
    setEmploymentDeleteAlert(true);
  };

  //------------Delete Education---------------

  const deleteEducation = async (e, data) => {
    e.stopPropagation();
    setDeleteEducationId(data.jseb_id);
    setEmploymentDeleteAlert(true);
  };

  //-------------Delete Skills-----------------

  const handleDeleteSkill = async (skillId) => {
    const postData = {
      skillid: skillId,
      language: localStorage.getItem("language"),
    };
    const result = await axiosAPI.post("/deleteSkill", postData);
    const { success, message } = result.data;
    if (success === true) {
      succesNotify(message);
      setIsApiCalled(Math.random());
    } else {
      errorNotify(message);
    }
  };

  //-------------Delete preferred Locations-----------------

  const deletePreferredLocations = async (locationId) => {
    const postData = {
      locationid: locationId,
      language: localStorage.getItem("language"),
    };
    const result = await axiosAPI.post("/deletePreferredLocation", postData);
    const { success, message } = result.data;
    if (success === true) {
      succesNotify(message);
      setIsApiCalled(Math.random());
    } else {
      errorNotify(message);
    }
  };
  //---------------Add Language Modal --------------------
  const [openLanguageAddModal, setLanguageAddModal] = useState(false);
  const LanguageAddModalClose = () => {
    setLanguageAddModal(false);
    setIsApiCalled(Math.random());
  };

  //-------------Delete Language-----------------

  const deleteLanguage = async (e, data) => {
    e.stopPropagation();
    setDeleteLanguageId(data.id);
    setEmploymentDeleteAlert(true);
  };

  //-------------Update Language-----------------
  const [openLanguageEditModal, setLanguageEditModel] = useState(false);
  const updateLanguage = (data) => {
    setLanguageData(data);
    setLanguageEditModel(true);
  };

  const closeLanguageEditModal = () => {
    setLanguageEditModel(false);
    setIsApiCalled(Math.random());
  };

  const Labels = useSelector((state) => state.allLabels.labels);
  const [userchangedcountry, setuserchangedcountry] = useState(null);
  return (
    <>
      {/* ------------------------Modals---------------------- */}

      <>
        {open === true ? (
          <EploymentModal
            open={open}
            handleClose={handleClose}
            btnlabel={`save`}
          />
        ) : null}

        {openEducationAddModel === true ? (
          <EducationModal
            open={openEducationAddModel}
            handleClose={handleCloseEducationModel}
          />
        ) : null}

        {skillsopen === true ? (
          <SkillsaddModal
            open={skillsopen}
            handleClose={skillCloseModal}
            skills={skillsId}
            setskills={setSkillsId}
            skillsName={skillsName}
          />
        ) : null}

        {/* {ContractModalopen === true ? (
          <ContractTypeAddModal
            open={ContractModalopen}
            handleClose={ContractTypeModalClose}
            skills={contracttypeid}
            setskills={setcontracttypeid}
          />
        ) : null} */}

        {openPreferedLocationsModal === true ? (
          <PreferedLocationModal
            open={openPreferedLocationsModal}
            handleClose={closePreferedLocationsModal}
            data={preferedLocations}
          />
        ) : null}
        {openCoverLetter === true ? (
          <CoverLetterModal
            open={openCoverLetter}
            handleClose={handleCloseCoverLetter}
            data={coverLetterData}
          />
        ) : null}
        {openPasswordReset === true ? (
          <EmployerPasswordChangeModal
            open={openPasswordReset}
            handleClose={setopenPasswordReset}
          />
        ) : null}
        {openEmploymentUpdateModal === true ? (
          // <EmployementUpdateModal
          //   openEmploymentUpdateModal={openEmploymentUpdateModal}
          //   employemtUpdateModalClose={employemtUpdateModalClose}
          //   data={updateEmployment}
          // />
          <EploymentModal
            open={openEmploymentUpdateModal}
            handleClose={employemtUpdateModalClose}
            data={updateEmployment}
            btnlabel={`update`}
          />
        ) : null}
        {openEducationUpdateModal === true ? (
          <EducationUpdateModal
            open={openEducationUpdateModal}
            handleClose={educationUpdateModalClose}
            data={updatedEducation}
          />
        ) : null}

        {openEmploymentDeleteAlert ? (
          <EmploymentDeleteAlert
            open={openEmploymentDeleteAlert}
            handleClose={closeEmploymentDeleteModal}
            employmentId={deleteEmploymentId}
            educationId={deleteEducationId}
            LanguageId={deleteLanguageId}
          />
        ) : null}
        <LanguageAddModal
          open={openLanguageAddModal}
          handleClose={LanguageAddModalClose}
        />
        <LanguageEditModal
          open={openLanguageEditModal}
          handleClose={closeLanguageEditModal}
          languageData={languageData}
        />
      </>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={7}>
          {/*--------------- Personal Information ---------------*/}
          <Paper elevation={0} sx={{ my: 1.5, height: "580px" }}>
            <Typography
              variant="subtitle1"
              sx={{ color: "#2B4947", p: 2, fontWeight: 600 }}
            >
              {Labels?.personalInformation}
            </Typography>
            <Divider />
            <Box sx={{ p: 2 }} className="jobseekerProfile">
              {/*--------------- Name ---------------------------------------*/}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <IconLabelTextField
                  icon={<PersonIcon />}
                  InputLabel={
                    <Typography variant="body2">
                      {Labels?.name} <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  type="text"
                  label={Labels?.name}
                  name="displayName"
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                  style={{ width: "100%" }}
                />
              )}
              {/*--------------- Email --------------------------------------*/}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <IconLabelTextField
                  icon={<EmailIcon />}
                  InputLabel={
                    <Typography variant="body2">
                      {Labels?.emailAddress}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  type="email"
                  label={Labels?.emailAddress}
                  name="emailid"
                  value={emailid}
                  readOnly
                  style={{ width: "100%" }}
                />
              )}
              {/*--------------- DOB ----------------------------------------*/}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <IconLabelTextField
                  icon={<CalendarMonthIcon />}
                  InputLabel={
                    <Typography variant="body2">
                      {Labels?.dateofBirth}{" "}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  type="date"
                  label={Labels?.dateofBirth}
                  name="dateOfBirth"
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                  style={{ width: "100%" }}
                  inputProps={{
                    max: maxDateFormatted,
                  }}
                />
              )}
              {/*--------------- Gender -------------------------------------*/}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ my: 1.5 }}
                >
                  <TransgenderIcon
                    sx={{ color: "grey", mr: 1, height: 25, width: 25 }}
                  />
                  <label style={{ color: "black", width: 230 }}>
                    {Labels?.Gender}
                  </label>
                  <Gender
                    value={userGender}
                    setValue={setUserGender}
                    style={{
                      width: "100%",
                      fontSize: "14px",
                    }}
                  />
                </Stack>
              )}
              {/*--------------- Country ------------------------------------*/}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ my: 1.5 }}
                >
                  <PublicIcon
                    sx={{ color: "grey", mr: 1, height: 25, width: 25 }}
                  />
                  <label style={{ color: "black", width: 230 }}>
                    {Labels?.Country} <span style={{ color: "red" }}>*</span>
                  </label>
                  <Country
                    value={countryId}
                    setValue={setCountryId}
                    setStateId={setStateId}
                    userchangedcountry={userchangedcountry}
                    setuserchangedcountry={setuserchangedcountry}
                    style={{
                      width: "100%",
                      fontSize: "14px",
                    }}
                  />
                </Stack>
              )}
              {/*--------------- State --------------------------------------*/}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ my: 1.5 }}
                >
                  <BungalowIcon
                    sx={{ color: "grey", mr: 1, height: 25, width: 25 }}
                  />
                  <label style={{ color: "black", width: 230 }}>
                    {Labels?.State} <span style={{ color: "red" }}>*</span>
                  </label>
                  <State
                    value={stateId}
                    setValue={setStateId}
                    country={countryId}
                    userchangedcountry={userchangedcountry}
                    setuserchangedcountry={setuserchangedcountry}
                    style={{
                      width: "100%",
                      fontSize: "14px",
                    }}
                  />
                </Stack>
              )}
              {/*--------------- City ---------------------------------------*/}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <IconLabelTextField
                  icon={<LocationOnIcon />}
                  InputLabel={
                    <Typography variant="body2">
                      {Labels?.City} <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  label={Labels?.City}
                  name="city"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  style={{ width: "100%" }}
                />
              )}
              {/*--------------- Address ------------------------------------*/}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <IconLabelTextField
                  icon={<HomeIcon />}
                  InputLabel={
                    <Typography variant="body2">
                      {Labels?.Address} <span style={{ color: "red" }}>*</span>
                    </Typography>
                  }
                  label={Labels?.Address}
                  name="address1"
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                  style={{ width: "100%" }}
                />
              )}
              <div style={{ display: "flex", justifyContent: "end" }}>
                <CustomButton
                  onClick={sendChatProfanity}
                  label={Labels?.Save}
                  style={{ padding: "5px 25px" }}
                  disabled={isButton1Disable}
                />
              </div>
            </Box>
          </Paper>

          {/*--------------- Resume -----------------------------------------*/}
          <Paper elevation={0} sx={{ my: 2 }}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 600, color: "#2B4947", p: 1.5 }}
            >
              {Labels?.Resume}
            </Typography>
            <Divider />
            <Stack
              direction="row"
              alignItems="center"
              sx={{ p: 1.5 }}
              className="jobseekerProfile"
            >
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                sx={{
                  // border: "1px solid #1c614e73",
                  p: "5px",
                  borderRight: "none",
                }}
              >
                <IconButton onClick={handleAttachFile}>
                  <AttachFileIcon />
                </IconButton>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={getResume}
                />
                <input
                  disabled
                  type="name"
                  value={resume ? resume : Labels?.UploadYourResumeHere}
                  style={{ border: "none", margin: "0 10px" }}
                />
                <CustomButton
                  onClick={uploadResume}
                  label={Labels?.Upload}
                  style={{ padding: "5px 25px" }}
                />
              </Stack>
            </Stack>
          </Paper>

          {/*--------------- Employment -------------------------------------*/}
          <Paper elevation={0} sx={{ my: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 1.5 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#2B4947" }}
              >
                {Labels?.EmploymentHistory}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "3px",
                  p: 0.5,
                  background: "#6da09c26",
                  borderColor: "#6da09c26",
                  color: "#000",
                  boxShadow: "none",
                  "&:hover": {
                    color: "#000",
                    background: "#6da09c26",
                  },
                  "&:active": {
                    color: "#000",
                    backgroundColor: "#6da09c26",
                  },
                  "&:disabled": { color: "white", backgroundColor: "#1C614E" },
                }}
                onClick={openEmploymentModel}
              >
                {Labels?.Add}
              </Button>
            </Stack>
            <Divider />
            {isLoading ? (
              <Box sx={{ p: 1.5 }} className="jobseekerProfile">
                <Skeleton variant="rectangular" height={100} animation="wave" />
              </Box>
            ) : (
              <Box sx={{ p: 1.5 }} className="jobseekerProfile">
                <Grid container spacing={2}>
                  {employments &&
                    employments.map((val, index) => (
                      <Grid item xs={12} md={6} lg={6} xl={4}>
                        <Box
                          key={val.jseh_id}
                          sx={{
                            p: 1.5,
                            mb: 2,
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                          }}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: 600 }}
                            >
                              {val.designationName}
                            </Typography>

                            <Stack direction="row" alignItems="center">
                              <EditIcon
                                sx={{
                                  cursor: "pointer",
                                  fontsize: "1.2rem",
                                  color: "rgba(0, 0, 0, 0.59)",
                                }}
                                onClick={() => EditEmployment(val)}
                              />
                              <DeleteIcon
                                sx={{
                                  cursor: "pointer",
                                  fontsize: "1.2rem",
                                  color: "rgba(0, 0, 0, 0.59)",
                                }}
                                onClick={(e) => deleteEmployment(e, val)}
                              />
                            </Stack>
                          </Stack>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "rgba(0, 0, 0, 0.59)",
                            }}
                          >
                            {val?.jobtypename}
                          </Typography>

                          <Stack>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "rgba(0, 0, 0, 0.59)",
                              }}
                            >
                              {val.company}{" "}
                              {val?.location ? `, ${val?.location}` : ""}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "rgba(0, 0, 0, 0.59)",
                              }}
                            >
                              {val.startyear} {val.startMonth} -{" "}
                              {val?.currentstatus == 1
                                ? "currently working"
                                : `${val.endyear} ${val.endMonth}`}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "rgba(0, 0, 0, 0.59)",
                              }}
                            >
                              {val?.responsibilities}
                            </Typography>
                          </Stack>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Box>
            )}
          </Paper>

          {/*--------------- Education History ------------------------------*/}
          <Paper elevation={0} sx={{ my: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 1.5 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#2B4947" }}
              >
                {Labels?.EducationHistory}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "3px",
                  p: 0.5,
                  background: "#6da09c26",
                  borderColor: "#6da09c26",
                  color: "#000",
                  boxShadow: "none",
                  "&:hover": {
                    color: "#000",
                    background: "#6da09c26",
                  },
                  "&:active": {
                    color: "#000",
                    backgroundColor: "#6da09c26",
                  },
                  "&:disabled": { color: "white", backgroundColor: "#1C614E" },
                }}
                onClick={openEducationModel}
              >
                {Labels?.Add}
              </Button>
            </Stack>
            <Divider />
            {isLoading ? (
              <Box sx={{ p: 1.5 }} className="jobseekerProfile">
                <Skeleton variant="rectangular" height={100} animation="wave" />
              </Box>
            ) : (
              <Box sx={{ p: 1.5 }} className="jobseekerProfile">
                <Grid container spacing={2}>
                  {educations &&
                    educations.map((val, index) => (
                      <Grid item xs={12} md={6} lg={4}>
                        <Box
                          key={val.jseh_id}
                          sx={{
                            p: 1.5,
                            mb: 2,
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                          }}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: 600 }}
                            >
                              {val.CourseName}
                            </Typography>
                            <Stack direction="row" alignItems="center">
                              <EditIcon
                                sx={{
                                  cursor: "pointer",
                                  fontsize: "1.2rem",
                                  color: "rgba(0, 0, 0, 0.59)",
                                }}
                                onClick={() => updateEducation(val)}
                              />
                              <DeleteIcon
                                sx={{
                                  cursor: "pointer",
                                  fontsize: "1.2rem",
                                  color: "rgba(0, 0, 0, 0.59)",
                                }}
                                onClick={(e) => deleteEducation(e, val)}
                              />
                            </Stack>
                          </Stack>

                          <Stack>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "rgba(0, 0, 0, 0.59)",
                              }}
                            >
                              {val.institution.length > 25
                                ? val.institution.slice(0, 25) + "..."
                                : val.institution}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "rgba(0, 0, 0, 0.59)",
                              }}
                            >
                              {getCountryNameById(val?.countryId)}
                            </Typography>
                            <Typography
                              variant="caption"
                              sx={{
                                color: "rgba(0, 0, 0, 0.59)",
                              }}
                            >
                              {val?.startMonth} {val?.startYear} -{" "}
                              {val?.endMonth} {val?.endYear}
                            </Typography>
                          </Stack>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Box>
            )}
          </Paper>
          {/*--------------- Languages --------------------------------------*/}
          <Paper elevation={0} sx={{ my: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 1.5 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#2B4947" }}
              >
                {Labels?.language}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "3px",
                  p: 0.5,
                  background: "#6da09c26",
                  borderColor: "#6da09c26",
                  color: "#000",
                  boxShadow: "none",
                  "&:hover": {
                    color: "#000",
                    background: "#6da09c26",
                  },
                  "&:active": {
                    color: "#000",
                    backgroundColor: "#6da09c26",
                  },
                  "&:disabled": { color: "white", backgroundColor: "#1C614E" },
                }}
                onClick={() => setLanguageAddModal(true)}
              >
                {Labels?.Add}
              </Button>
            </Stack>
            <Divider />
            {isLoading ? (
              <Box sx={{ p: 1.5 }} className="jobseekerProfile">
                <Skeleton variant="rectangular" height={100} animation="wave" />
              </Box>
            ) : (
              <Box sx={{ p: 1 }} className="jobseekerProfile">
                <Grid container spacing={2}>
                  {Languages &&
                    Languages.sort((a, b) => {
                      const aPriority = a.writtenid === 4 || a.spokenid === 4 ? 1 : 0;
                      const bPriority = b.writtenid === 4 || b.spokenid === 4 ? 1 : 0;
                      if (aPriority !== bPriority) {
                        return bPriority - aPriority;
                      }
                      if (a.writtenid !== b.writtenid) {
                        return b.writtenid - a.writtenid;
                      }
                      return b.spokenid - a.spokenid;
                    }).map((val, index) => (
                      <Grid item xs={12} md={6} lg={4} key={val.jseh_id}>
                        <Box
                          sx={{
                            p: 2,
                            border: "1px solid rgba(0, 0, 0, 0.12)",
                            mb: 2,
                          }}
                        >
                          <Stack direction="row" justifyContent="space-between">
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: 600, width: "0%" }}
                            >
                              {val.language}
                            </Typography>

                            <Stack direction="row" alignItems="center">
                              <EditIcon
                                sx={{
                                  cursor: "pointer",
                                  fontsize: "1.2rem",
                                  color: "rgba(0, 0, 0, 0.59)",
                                }}
                                onClick={() => updateLanguage(val)}
                              />
                              <DeleteIcon
                                sx={{
                                  cursor: "pointer",
                                  fontsize: "1.2rem",
                                  color: "rgba(0, 0, 0, 0.59)",
                                }}
                                onClick={(e) => deleteLanguage(e, val)}
                              />
                            </Stack>
                          </Stack>
                          <Stack>
                            <Stack direction="row">
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  color: "rgba(0, 0, 0, 0.59)",
                                }}
                              >
                                {Labels?.Written}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "rgba(0, 0, 0, 0.59)",
                                  mx: 2,
                                  width: "200px",
                                }}
                              >
                                {val?.written}
                              </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center">
                              <Typography
                                variant="subtitle2"
                                sx={{
                                  color: "rgba(0, 0, 0, 0.59)",
                                }}
                              >
                                {Labels?.Spoken}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{
                                  color: "rgba(0, 0, 0, 0.59)",
                                  mx: 2,
                                  width: "200px",
                                }}
                              >
                                {val?.spoken}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Box>
                      </Grid>
                    ))}
                </Grid>
              </Box>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12} lg={5}>
          {/*--------------- Enable Two-Factor Authentication ---------------*/}
          <Paper elevation={0} sx={{ my: 1 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 1.5 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#2B4947" }}
              >
                {Labels?.EnableTwoFactor}
              </Typography>
              <Switch
                checked={Ischecked}
                color="success"
                onChange={handleToggle}
              />
            </Stack>
          </Paper>
          {/*--------------- Career Profile ---------------------------------*/}
          <Paper elevation={0} sx={{ my: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 1.5 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#2B4947" }}
              >
                {Labels?.careerProfile}
              </Typography>
            </Stack>
            <Divider />
            <Box sx={{ p: 1.5 }} className="jobseekerProfile">
              {/* ---------------Designation--------------- */}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ my: 1.5 }}
                >
                  <WorkIcon
                    sx={{ color: "grey", mr: 1, height: 25, width: 25 }}
                  />
                  <label style={{ color: "#413E3E", width: 230 }}>
                    {Labels?.JobPosition}
                  </label>
                  <DesignationProfile
                    value={userDesignation}
                    setValue={setUserDesignation}
                    label={Labels?.JobPosition}
                    style={{
                      width: "100%",
                      fontSize: "14px",
                    }}
                  />
                </Stack>
              )}
              {/* ---------------Experience level--------------- */}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ my: 1.5 }}
                >
                  <AssessmentIcon
                    sx={{ color: "grey", mr: 1, height: 25, width: 25 }}
                  />
                  <label style={{ color: "#413E3E", width: 230 }}>
                    {Labels?.ExperienceLevel}
                  </label>
                  <ExperienceLevel
                    value={userExperienceLevel}
                    setValue={setUserExperienceLevel}
                    style={{
                      width: "100%",
                      fontSize: "14px",
                    }}
                  />
                </Stack>
              )}

              {/* ---------------Total Experience--------------- */}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <IconLabelTextMUI
                  icon={<PieChartIcon />}
                  InputLabel={Labels?.TotalExperience}
                  label={Labels?.TotalExperience}
                  type="text"
                  name="totalExperience"
                  value={totalExperience}
                  getCareerProfile={(e) => {
                    setTotalExperience(e.target.value);
                  }}
                  style={{ width: "100%" }}
                />
              )}
              {/* -------------Current Company----------------- */}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <IconLabelTextField
                  icon={<ImportantDevicesIcon />}
                  InputLabel={Labels?.CurrentLastCompany}
                  label={Labels?.CurrentLastCompany}
                  type="text"
                  name="currentEmployer"
                  value={currentEmployer}
                  onChange={(e) => setCurrentEmployer(e.target.value)}
                  style={{ width: "100%" }}
                />
              )}

              {/* ---------------Current Salary (New) --------------- */}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1 }}
                />
              ) : (
                <Grid
                  item
                  xs={12}
                  md={12}
                  justifyContent="space-between"
                  direction="row"
                  display="flex"
                >
                  <InputWithLabel
                    label={Labels.Amount}
                    name="currentSalary"
                    type="number"
                    inputProps={{ min: 0 }}
                    style={{
                      width: "100%",
                      mt: -0.6,
                      position: "relative",
                      right: "3px",
                    }}
                    InputLabel={
                      <>
                        <span style={{ position: "relative", bottom: "2px" }}>
                          {Labels?.Currentsalary}
                        </span>
                      </>
                    }
                    value={currentSalary}
                    onChange={(e) => setCurrentSalary(e.target.value)}
                  />

                  <Currency
                    name="setCurrency"
                    value={currency}
                    setValue={setcurrency}
                    style={{ width: "97%" }}
                  />

                  <SalaryPeriod
                    style={{ width: "98%", position: "relative", left: "1px" }}
                    InputLabel={Labels.Period}
                    label={Labels?.Workinghours}
                    name="salaryPeriod"
                    value={salaryPeriod}
                    setValue={setsalaryPeriod}
                  />
                </Grid>
              )}

              {/* ---------------Expected Salary (New) --------------- */}
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1 }}
                />
              ) : (
                <Grid
                  item
                  xs={12}
                  md={12}
                  justifyContent="space-between"
                  direction="row"
                  display="flex"
                >
                  <InputWithLabel
                    label={Labels.Amount}
                    name="expectedSalary"
                    type="number"
                    inputProps={{ min: 0 }}
                    style={{
                      width: "98%",
                      mt: -0.6,
                      position: "relative",
                      right: "3px",
                    }}
                    InputLabel={
                      <>
                        <span style={{ position: "relative", bottom: "2px" }}>
                          {Labels?.ExpectedSalary}
                        </span>
                      </>
                    }
                    value={expectedSalary}
                    onChange={(e) => setExpectedSalary(e.target.value)}
                  />

                  <Currency
                    name="setCurrency"
                    value={currency2}
                    setValue={setcurrency2}
                    style={{ width: "98%", position: "relative", right: "4px" }}
                  />

                  <SalaryPeriod
                    style={{ width: "98%" }}
                    InputLabel={Labels.Period}
                    label={Labels?.Workinghours}
                    name="salaryPeriod"
                    value={salaryPeriod2}
                    setValue={setsalaryPeriod2}
                  />
                </Grid>
              )}

              <div style={{ display: "flex", justifyContent: "end" }}>
                <CustomButton
                  onClick={submitCareerProfile}
                  label={Labels?.Save}
                  style={{ padding: "5px 25px" }}
                  disabled={isButton2Disable}
                />
              </div>
            </Box>
          </Paper>

          {/*--------------- Contract Type -------------------------------------*/}
          <Paper
            elevation={0}
            sx={{ my: 2, maxHeight: "500px", overflowY: `auto` }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 1 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#2B4947" }}
              >
                {Labels?.ContractType}
              </Typography>
            </Stack>
            <Box sx={{ p: 1 }} className="jobseekerProfile">
              <Divider />
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1 }}
                />
              ) : (
                <ContractTypeAddModal
                  open={ContractModalopen}
                  handleClose={ContractTypeModalClose}
                  skills={contracttypeid}
                  setskills={setcontracttypeid}
                  contractname={contractname}
                />
              )}
            </Box>
          </Paper>

          {/*--------------- Key Skills -------------------------------------*/}
          <Paper
            elevation={0}
            sx={{ my: 2, maxHeight: "500px", overflowY: `auto` }}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 1.5 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#2B4947" }}
              >
                {Labels?.KeySkills}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "3px",
                  p: 0.5,
                  background: "#6da09c26",
                  borderColor: "#6da09c26",
                  color: "#000",
                  boxShadow: "none",
                  "&:hover": {
                    color: "#000",
                    background: "#6da09c26",
                  },
                  "&:active": {
                    color: "#000",
                    backgroundColor: "#6da09c26",
                  },
                  "&:disabled": { color: "white", backgroundColor: "#1C614E" },
                }}
                onClick={OpenSkillModal}
              >
                {Labels?.Add}
              </Button>
            </Stack>
            <Divider />
            <Box sx={{ p: 1 }} className="jobseekerProfile">
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <Stack direction="column">
                  {skillsName.map((val, index) => (
                    <Stack
                      key={index}
                      direction={{ xs: "column", sm: "row" }} // Responsive: stack vertically on small screens, horizontally on larger screens
                      alignItems="center"
                      spacing={2}
                      sx={{
                        margin: "4px 0px",
                        p: 1,
                        border: "1px solid #e0e0e0",
                        borderRadius: 2,
                      }}
                    >
                      {/* Skill Name */}
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 600,
                          color: "#2B4947",
                          flexBasis: { xs: "100%", sm: "25%" }, // Full width on small screens, 25% width on larger screens
                          display: { xs: "none", sm: "block" },
                        }}
                      >
                        {val.name}
                      </Typography>
                      <Typography
                        sx={{
                          minWidth: "50px",
                          textAlign: "center",
                          fontWeight: 500,
                          color: `black`, // Color change based on value
                          display: { xs: "block", sm: "none" },
                        }}
                      >
                        {val.name} {val?.detail?`- ${val?.detail}%`:""}
                      </Typography>

                      {/* Progress Bar */}
                      <Box sx={{ flexGrow: 1, width: "100%" }}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={val.detail}
                          sx={{
                            "& .MuiLinearProgress-bar": {
                              backgroundColor:
                                val.detail >= 50 ? "#1C614E" : "#FFD700", // Green if 50% or above, yellow if below
                            },
                          }}
                        />
                      </Box>

                      {/* Percentage Value */}
                      <Typography
                        sx={{
                          minWidth: "50px",
                          textAlign: "center",
                          fontWeight: 500,
                          color: `black`, // Color change based on value
                          display: { xs: "none", sm: "block" },
                        }}
                      >
                         {val?.detail?`${val?.detail}%`:""}
                      </Typography>
                      <DeleteIcon
                        onClick={() => handleDeleteSkill(val.id)}
                        sx={{ cursor: "pointer", color: "#1C614E" }}
                      />
                    </Stack>
                  ))}
                </Stack>
              )}
            </Box>
          </Paper>

          {/*--------------- Preferred Locations ----------------------------*/}
          <Paper elevation={0} sx={{ my: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 1.5 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#2B4947" }}
              >
                {Labels?.PreferredLocations}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  borderRadius: "3px",
                  p: 0.5,
                  background: "#6da09c26",
                  borderColor: "#6da09c26",
                  color: "#000",
                  boxShadow: "none",
                  "&:hover": {
                    color: "#000",
                    background: "#6da09c26",
                  },
                  "&:active": {
                    color: "#000",
                    backgroundColor: "#6da09c26",
                  },
                  "&:disabled": { color: "white", backgroundColor: "#1C614E" },
                }}
                onClick={() => setPreferedLocationsModal(true)}
              >
                {Labels?.Add}
              </Button>
            </Stack>
            <Divider />
            <Box sx={{ p: 1.5, overflow: "auto" }} className="jobseekerProfile">
              {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : (
                <Stack direction="row" alignItems="center" flexWrap={"wrap"}>
                  {preferedLocations.map((val, index) => (
                    <Chip
                      key={index}
                      label={
                        <span>
                          {val.city}, {val.state}, {val.country}
                        </span>
                      }
                      sx={{ m: 1 }}
                      onDelete={() => deletePreferredLocations(val.jspl_id)}
                    />
                  ))}
                </Stack>
              )}
            </Box>
          </Paper>
          {/*--------------- Change Password --------------------------------*/}
          <Paper elevation={0} sx={{ my: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 1.5 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#2B4947" }}
              >
                {Labels?.ChangePassword}
              </Typography>
              <DriveFileRenameOutlineOutlinedIcon
                sx={{
                  color: "rgba(113, 109, 109, 0.9)",
                  cursor: "pointer",
                }}
                onClick={openPasswordResetModal}
              />
            </Stack>
            <Divider />
            <Box sx={{ p: 1.5 }} className="jobseekerProfile">
              <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.75)" }}>
                {Labels.change_password_text}
              </Typography>
            </Box>
          </Paper>
          {/*--------------- Profile Summary --------------------------------*/}
          <Paper elevation={0} sx={{ my: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ p: 1.5 }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#2B4947" }}
              >
                {Labels?.profileSummary}
              </Typography>
              <DriveFileRenameOutlineOutlinedIcon
                sx={{
                  color: "rgba(113, 109, 109, 0.9)",
                  cursor: "pointer",
                }}
                onClick={openCoverLetterModel}
              />
            </Stack>
            <Divider />
            <Box sx={{ p: 1.5 }} className="jobseekerProfile">
              <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.75)" }}>
                {coverLetterData && coverLetterData.length > 150
                  ? coverLetterData.slice(0, 150) + "..."
                  : coverLetterData}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default JobseekerProfile;
