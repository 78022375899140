import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import {
  Button,
  Chip,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { axiosAPI } from "../../Axios/Axios";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { infoNotify, succesNotify } from "../../CommonCode/Commonfunc";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 200, // Adjust the maximum height as needed
      width: 250,
    },
  },
};

function JobSearchFilter({ setopenadvancedsearch }) {
  const Labels = useSelector((state) => state.allLabels.labels);
  const uid = localStorage.getItem("uid");
  const redirect = useNavigate();

  const [expanded1, setExpanded1] = useState(false); // expanding value for filters
  const [expanded2, setExpanded2] = useState(false); // expanding value for filters
  const [expanded3, setExpanded3] = useState(false); // expanding value for filters
  const [expanded4, setExpanded4] = useState(false); // expanding value for filters
  const [expanded5, setExpanded5] = useState(false); // expanding value for filters
  const [expandedsave, setexpandedsave] = useState(false);

  const [textvalue, settextvalue] = useState(Labels.Open); // For Toggling "Open" and "Close" text
  const [filterapply, setfilterapply] = useState(false);

  const applyfilterfn = async () => {
    setTimeout(() => {
      setfilterapply(true);
    }, 500);
  };

  useEffect(() => {
    if (filterapply) {
      ApplyFilter();
    }
  }, [filterapply]);

  const handleExpandAll = () => {
    setExpanded1(true);
    setExpanded2(true);
    setExpanded3(true);
    setExpanded4(true);
    setExpanded5(true);
    settextvalue(Labels.close);
  };
  // Expand all filters btn fn
  const handleCloseAll = () => {
    setExpanded1(false);
    setExpanded2(false);
    setExpanded3(false);
    setExpanded4(false);
    setExpanded5(false)
    settextvalue(Labels.Open);
  };
  // Close all filters btn fn

  const [countrylist, setcountrylist] = useState([]); // country listing
  const [statelist, setstatelist] = useState([]); // state listing after selecting country
  const [officePresencelist, setofficePresencelist] = useState([]); // for listing office presence (hybrid, office, remote)
  const [ContractTypeList, setContractTypeList] = useState([]); // for listing different contract list (Full time, part time etc..)
  
  const [categorylist,setcategorylist] = useState([])
  const [languageList, setLanguageList] = useState([]); // for listing all language

  const [currentcategory, setcurrentcategory] = useState(""); // for selecting selected language to be added to the array of languages
  const [selectedCategory, setselectedCategory] = useState([]); // array of selected languages

  const [currentLanguage, setCurrentLanguage] = useState(""); // for selecting selected language to be added to the array of languages
  const [selectedLanguages, setSelectedLanguages] = useState([]); // array of selected languages

  const [title, settitle] = useState(""); // title for filter
  const [country, setcountry] = useState(""); // country for filter
  const [state, setstate] = useState(""); // state for filter
  const [city, setcity] = useState(""); // city for filter
  const [radius, setRadius] = useState(""); // radius for filter
  const [officePresence, setofficePresence] = useState([]); // office presence values (array of ids)
  const [ContractType, setContractType] = useState([]); // contract type values (array of ids)
  const [language, setlanguage] = useState([]); // language values (array of ids)
  const [category,setcategory] = useState([])
  const [SavedFilters, setSavedFilters] = useState([]);

  const [filtername, setfiltername] = useState("");

  const getCountries = async () => {
    const result = await axiosAPI.get("/getCountries");
    const { success, data } = await result.data;
    if (success === true) {
      setcountrylist(data);
    } else {
      setcountrylist([]);
    }
  };
  // API for calling data
  const getJobType = async () => {
    const result = await axiosAPI.get("/getTypes");
    const { success, data } = result.data;
    if (success === true) {
      setContractTypeList(data);
    }
  };
   // API for calling data
   const getjobcategories = async () => {
    try {
      const result = await axiosAPI("/getAllCategories");
      const { success, message, data } = result.data;
      if (success) {
        setcategorylist(data);
      } 
    } catch (error) {
      //// console.log(error.message);
    }
  };

  // API for calling data
  const getOfficePresence = async () => {
    const result = await axiosAPI.get("/getOfficePresence");
    const { success, data } = await result.data;
    if (success === true) {
      setofficePresencelist(data);
    }
  };
  // API for calling data
  const getAllLanguage = async () => {
    const result = await axiosAPI.get("/getAllLanguages");
    const { success, data } = result.data;
    if (success === true) {
      setLanguageList(data);
    } else {
      setLanguageList([]);
    }
  };

  const getFilters = async () => {
    const result = await axiosAPI.get("/ListsearchJob");
    const { success, data } = result.data;
    setSavedFilters(data);
  };

  // API for calling data

  useEffect(() => {
    getCountries();
    getJobType();
    getOfficePresence();
    getAllLanguage();
    getFilters();
    getjobcategories()
  }, []);

  useEffect(() => {
    const getStates = async () => {
      const result = await axiosAPI.get(`/getStates?countryID=${country}`);
      const { success, data } = await result.data;
      if (success === true) {
        setstatelist(data);
      } else {
        setstatelist([]);
      }
    };
    if (country.length != 0) {
      getStates();
    }
  }, [country]);
  // State APi called each time country is selected

  useEffect(() => {
    const ids = selectedLanguages.map((lang) => lang.l_id);
    setlanguage(ids);
  }, [selectedLanguages]);

  useEffect(() => {
    const ids = selectedCategory.map((lang) => lang.id);
    setcategory(ids);
  }, [selectedCategory]);


  // Only language id selected from list of language added

  const handleChange = (id) => (event) => {
    if (event.target.checked) {
      setofficePresence((prev) => [...prev, id]);
    } else {
      setofficePresence((prev) => prev.filter((itemId) => itemId !== id));
    }
  };
  // for office presence selection

  const handleChange2 = (id) => (event) => {
    if (event.target.checked) {
      setContractType((prev) => [...prev, id]);
    } else {
      setContractType((prev) => prev.filter((itemId) => itemId !== id));
    }
  };
  // for contract type selection

  const handleAddLanguage = () => {
    if (
      currentLanguage &&
      !selectedLanguages.some((lang) => lang.l_id === currentLanguage)
    ) {
      const selectedLanguage = languageList.find(
        (lang) => lang.l_id === currentLanguage
      );
      setSelectedLanguages([...selectedLanguages, selectedLanguage]);
      setCurrentLanguage(""); // reset the current language selection
    }
  };
  // For adding language when clicking add btn

  const handleDeleteLanguage = (languageId) => {
    setSelectedLanguages(
      selectedLanguages.filter((lang) => lang.l_id !== languageId)
    );
  };
  // Deleting selected language

  const handleAddCategory = () => {
    if (
      currentcategory &&
      !selectedCategory.some((lang) => lang.id === currentcategory)
    ) {
      const selectedCategoryy = categorylist.find(
        (lang) => lang.id === currentcategory
      );      

      setselectedCategory([...selectedCategory, selectedCategoryy]);
      setcurrentcategory(""); // reset the current language selection
    }
  };
  // For adding language when clicking add btn

  const handleDeleteCategory = (languageId) => {
    setselectedCategory(
      selectedCategory.filter((lang) => lang.id !== languageId)
    );
  };
  // Deleting selected language




  const handleRadiusChange = (e) => {
    const value = e.target.value;
    // Allow only numbers and prevent negative values
    if (/^\d*$/.test(value)) {
      setRadius(value);
    }
  };

  const deleteFilter = async (id) => {
    const result = await axiosAPI.post(`/deleteSearchjob`, { sjid: id });
    const { success, data } = result.data;
    if (result.data.message == "Record deleted successfully") {
      succesNotify(Labels.FilterDeleted);
    }
    getFilters();
  };
  // For deleting fllter

  const handleSaveFilter = async (e) => {
    const result = await axiosAPI.post("/searchJobSave", {
      jobsearch: title,
      country_id: country,
      state_id: state,
      city: city,
      radius: radius,
      officePresence: officePresence.toString(),
      type: ContractType.toString(),
      job_language: language.toString(),
      name: filtername,
      category:category.toString()
    });

    const { success, data } = result.data;
    setfiltername(" ");
    if (result.data.message == "Search Job Added Successfully") {
      succesNotify(result.data.message);
      setsavefilterview(false);
      setexpandedsave(true);
    } else {
      infoNotify(result.data.message);
      setsavefilterview(false);
    }

    getFilters();
  };
  // For saving a filter

  const [selectedFilter, setSelectedFilter] = useState(null);

  const handleItemClick = (filter) => {
    setSelectedFilter(filter);
  };

  const selectFilter = (a) => {
    settitle(a?.jobsearch ? a?.jobsearch : "");
    setcountry(a?.country_id ? a?.country_id : "");
    setstate(a?.state_id ? a?.state_id : "");
    setcity(a?.city ? a?.city : "");
    setRadius(a?.radius ? a?.radius : "");
    setofficePresence(a?.officePresence? a?.officePresence.split(",").map((id) => parseInt(id, 10)): "");
    setContractType(a?.type ? a?.type.split(",").map((id) => parseInt(id, 10)) : "");
    const backenddata = a?.job_language? a?.job_language?.split(",").map((id) => parseInt(id, 10)): [];
    const filteredLanguages = languageList?.filter((language) => backenddata?.includes(language?.l_id));
    setSelectedLanguages(filteredLanguages);
    const backenddata2 = a?.job_category? a?.job_category?.split(",").map((id) => parseInt(id, 10)): [];
    const filteredCategories= categorylist?.filter((language) => backenddata2?.includes(language?.id));
    setselectedCategory(filteredCategories)
  };
  // For selecting a filter from the saved filter list

  const [savefilterview, setsavefilterview] = useState(false);
  const ApplyFilter = () => {
    const postData = {
      jobsearch: title,
      country_id: country,
      state_id: state,
      city: city,
      radius: radius,
      officePresence: officePresence.toString(),
      type: ContractType.toString(),
      job_language: language.toString(),
      category:category.toString()
    };
    setopenadvancedsearch(false);
    setfilterapply(false);
    redirect("/jobList", { state: postData ? postData : "" });
  };

  // Final filter apply btn

  const ResetFn = () => {
    const postData = {
      jobsearch: "",
      country_id: "",
      state_id: "",
      city: "",
      officePresence: "",
      type: "",
      language: "",
    };

    settitle("");
    setcountry("");
    setstate("");
    setcity("");
    setRadius("");
    setofficePresence([]);
    setContractType([]);
    setlanguage([]);
    setSelectedLanguages([]);
    setSelectedFilter(null);
    setselectedCategory([])

    setTimeout(() => {
      setopenadvancedsearch(false);
      redirect("/jobList", { state: postData ? postData : "" });
    }, 500);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: "10px 20px",
        maxHeight: "95vh",
        overflowY: "auto",
      }}
    >
      <CloseIcon
        sx={{ float: "right", cursor: "pointer" }}
        onClick={() => {
          setopenadvancedsearch(false);
        }}
      />
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: 600, color: "#1c614d" }}
      >
        <h2 style={{ textAlign: "center" }}> {Labels.Filters} </h2>
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Item>
            {/* Filter content */}
            <>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Item>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        <Item>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 600,
                              color: "#1c614d",
                              marginTop: "10px",
                            }}
                          >
                            {Labels.JobTitle}
                          </Typography>
                        </Item>
                      </Grid>
                      <Grid item xs={9}>
                        <Item>
                          <TextField
                            id="outlined-basic"
                            label={Labels.JobTitle}
                            variant="outlined"
                            value={title}
                            InputProps={{ style: { height: "45px" } }}
                            onChange={(e) => {
                              settitle(e.target.value);
                            }}
                          />
                        </Item>
                      </Grid>
                    </Grid>
                    <p style={{ textAlign: "right" }}>
                      {textvalue == Labels.Open && (
                        <Button
                          variant="contained"
                          color="success"
                          sx={{
                            backgroundColor: "#1c614d",
                            "&:hover": {
                              backgroundColor: "#1c614d",
                            },
                            "&:active": {
                              backgroundColor: "#1c614d",
                            },
                          }}
                          onClick={handleExpandAll}
                        >
                          {textvalue} {Labels.AllFilters}
                        </Button>
                      )}
                      {textvalue == Labels.close && (
                        <Button
                          variant="contained"
                          color="success"
                          sx={{
                            backgroundColor: "#1c614d",
                            "&:hover": {
                              backgroundColor: "#1c614d",
                            },
                            "&:active": {
                              backgroundColor: "#1c614d",
                            },
                          }}
                          onClick={handleCloseAll}
                        >
                          {textvalue} {Labels.AllFilters}
                        </Button>
                      )}
                    </p>
                  </Item>
                </Grid>
                <Grid item xs={12}>
                  <Item
                    sx={{
                      maxHeight: "300px",
                      overflowY: "auto",
                      padding: "10px",
                    }}
                  >
                    <Accordion sx={{ boxShadow: "none" }} expanded={expanded1}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        onClick={() => {
                          setExpanded1(!expanded1);
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 600, color: "#1c614d" }}
                        >
                          {Labels.location}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2} sx={{ padding: "0px" }}>
                          <Grid item xs={3}>
                            <Item>
                              <p
                                style={{
                                  fontSize: "17px",
                                  color: "black",
                                  position: "relative",
                                  bottom: "3px",
                                  textAlign: "left",
                                }}
                              >
                                {" "}
                                {Labels.Country}
                              </p>
                            </Item>
                          </Grid>
                          <Grid item xs={9}>
                            <Item>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  {Labels.Country}
                                </InputLabel>
                                <Select
                                  MenuProps={MenuProps}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={country}
                                  label={Labels.Country}
                                  onChange={(e) => {
                                    setcountry(e.target.value);
                                  }}
                                >
                                  {countrylist.length
                                    ? countrylist.map((a) => {
                                        return (
                                          <MenuItem value={a.id}>
                                            <p
                                              style={{
                                                textAlign: "left",
                                                margin: "0px",
                                                padding: "0px",
                                              }}
                                            >
                                              {a.name}
                                            </p>
                                          </MenuItem>
                                        );
                                      })
                                    : null}
                                </Select>
                              </FormControl>
                            </Item>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ padding: "0px" }}>
                          <Grid item xs={3}>
                            <Item>
                              <p
                                style={{
                                  fontSize: "17px",
                                  color: "black",
                                  position: "relative",
                                  bottom: "3px",
                                  textAlign: "left",
                                }}
                              >
                                {" "}
                                {Labels.State}
                              </p>
                            </Item>
                          </Grid>
                          <Grid item xs={9}>
                            <Item>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  {Labels.State}
                                </InputLabel>
                                <Select
                                  MenuProps={MenuProps}
                                  disabled={country.length == 0}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  label={Labels.State}
                                  value={state}
                                  onChange={(e) => {
                                    setstate(e.target.value);
                                  }}
                                >
                                  {statelist.length
                                    ? statelist.map((a) => {
                                        return (
                                          <MenuItem value={a.id}>
                                            <p
                                              style={{
                                                textAlign: "left",
                                                margin: "0px",
                                                padding: "0px",
                                              }}
                                            >
                                              {a.name}
                                            </p>
                                          </MenuItem>
                                        );
                                      })
                                    : null}
                                </Select>
                              </FormControl>
                            </Item>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ padding: "0px" }}>
                          <Grid item xs={3}>
                            <Item>
                              <p
                                style={{
                                  fontSize: "17px",
                                  color: "black",
                                  position: "relative",
                                  bottom: "3px",
                                  textAlign: "left",
                                }}
                              >
                                {" "}
                                {Labels.City}
                              </p>
                            </Item>
                          </Grid>
                          <Grid item xs={9}>
                            <Item>
                              <FormControl fullWidth>
                                <TextField
                                  id="outlined-basic"
                                  label={Labels.City}
                                  variant="outlined"
                                  value={city}
                                  InputProps={{ style: { height: "45px" } }}
                                  onChange={(e) => {
                                    setcity(e.target.value);
                                  }}
                                />
                              </FormControl>
                            </Item>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={{ padding: "0px" }}>
                          <Grid item xs={3}>
                            <Item>
                              <p
                                style={{
                                  fontSize: "17px",
                                  color: "black",
                                  position: "relative",
                                  bottom: "3px",
                                  textAlign: "left",
                                }}
                              >
                                {" "}
                                {Labels.Radius}
                              </p>
                            </Item>
                          </Grid>
                          <Grid item xs={9}>
                            <Item>
                              <TextField
                                id="outlined-basic"
                                label={Labels.Radius}
                                variant="outlined"
                                value={radius}
                                InputProps={{ style: { height: "45px" } }}
                                onChange={handleRadiusChange}
                              />
                            </Item>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>              
                    <Accordion sx={{ boxShadow: "none" }} expanded={expanded2}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        onClick={() => {
                          setExpanded2(!expanded2);
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 600, color: "#1c614d" }}
                        >
                          {Labels.Languages}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={9}>
                            <Item>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  {Labels.Languages}
                                </InputLabel>
                                <Select
                                  MenuProps={MenuProps}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={currentLanguage}
                                  label={Labels.Languages}
                                  onChange={(e) =>
                                    setCurrentLanguage(e.target.value)
                                  }
                                >
                                  {languageList.map((a) => (
                                    <MenuItem key={a.l_id} value={a.l_id}>
                                      <p
                                        style={{
                                          textAlign: "left",
                                          margin: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        {a.language}
                                      </p>
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Item>
                          </Grid>
                          <Grid item xs={3}>
                            <Button
                              variant="contained"
                              color="success"
                              sx={{ backgroundColor: "#1c614d" }}
                              onClick={handleAddLanguage}
                            >
                              {Labels.Add}
                            </Button>
                          </Grid>
                        </Grid>

                        <List>
                          {selectedLanguages.map((lang) => (
                            <ListItem
                              key={lang.l_id}
                              sx={{
                                padding: "0px 5px",
                                margin: "5px",
                                borderRadius: "10px",
                                background: "#1c614d2f",
                              }}
                            >
                              <ListItemText
                                primary={lang.language}
                                sx={{ padding: "0px 5px" }}
                              />
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleDeleteLanguage(lang.l_id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItem>
                          ))}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ boxShadow: "none" }} expanded={expanded3}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        onClick={() => {
                          setExpanded3(!expanded3);
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 600, color: "#1c614d" }}
                        >
                          {Labels.OfficePresence}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {officePresencelist.length
                          ? officePresencelist.map((a) => (
                              <Grid container spacing={2} key={a.id}>
                                <Grid item xs={3}>
                                  <Item>
                                    <p
                                      style={{
                                        fontSize: "17px",
                                        color: "black",
                                        position: "relative",
                                        bottom: "3px",
                                        textAlign: "left",
                                      }}
                                    >
                                      {a.name}
                                    </p>
                                  </Item>
                                </Grid>
                                <Grid item xs={9}>
                                  <Item>
                                    <Checkbox
                                      sx={{ float: "left" }}
                                      onChange={handleChange(a.id)}
                                      checked={officePresence.includes(a.id)}
                                    />
                                  </Item>
                                </Grid>
                              </Grid>
                            ))
                          : null}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ boxShadow: "none" }} expanded={expanded4}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        onClick={() => {
                          setExpanded4(!expanded4);
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 600, color: "#1c614d" }}
                        >
                          {Labels.ContractType}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {ContractTypeList.length
                          ? ContractTypeList.map((a) => (
                              <Grid container spacing={2} key={a.id}>
                                <Grid item xs={3}>
                                  <Item>
                                    <p
                                      style={{
                                        fontSize: "17px",
                                        color: "black",
                                        position: "relative",
                                        bottom: "3px",
                                        textAlign: "left",
                                      }}
                                    >
                                      {a.name}
                                    </p>
                                  </Item>
                                </Grid>
                                <Grid item xs={9}>
                                  <Item>
                                    <Checkbox
                                      sx={{ float: "left" }}
                                      onChange={handleChange2(a.id)}
                                      checked={ContractType.includes(a.id)}
                                    />
                                  </Item>
                                </Grid>
                              </Grid>
                            ))
                          : null}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion sx={{ boxShadow: "none" }} expanded={expanded5}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        onClick={() => {
                          setExpanded5(!expanded5);
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 600, color: "#1c614d" }}
                        >
                          {Labels.categories}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={9}>
                            <Item>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                {Labels.categories}
                                </InputLabel>
                                <Select
                                  MenuProps={MenuProps}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={currentcategory}
                                  label={Labels.Languages}
                                  onChange={(e) =>
                                    setcurrentcategory(e.target.value)
                                  }
                                >
                                  {categorylist?.map((a) => (
                                    <MenuItem value={a.id}>
                                      <p
                                        style={{
                                          textAlign: "left",
                                          margin: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        {a?.categoryName}
                                      </p>
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Item>
                          </Grid>
                          <Grid item xs={3}>
                            <Button
                              variant="contained"
                              color="success"
                              sx={{ backgroundColor: "#1c614d" }}
                              onClick={handleAddCategory}
                            >
                              {Labels.Add}
                            </Button>
                          </Grid>
                        </Grid>

                        <List>
                          {selectedCategory.map((lang) => (
                            <ListItem
                              key={lang.id}
                              sx={{
                                padding: "0px 5px",
                                margin: "5px",
                                borderRadius: "10px",
                                background: "#1c614d2f",
                              }}
                            >
                              <ListItemText
                                sx={{ padding: "0px 5px" }}
                                primary={lang.categoryName}
                              />
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleDeleteCategory(lang.id)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </ListItem>
                          ))}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  </Item>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ padding: "20px 0px" }}>
                <Grid item xs={4}>
                  <Item>
                    <Button
                      variant="contained"
                      color="success"
                      sx={{
                        backgroundColor: "white",
                        color: "#1c614d",
                        border: "2px solid #1c614d",
                        width: "100%",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "white",
                        },
                        "&:active": {
                          backgroundColor: "white",
                        },
                        "&:disabled": {
                          color: "white",
                          backgroundColor: "#1C614E",
                        },
                      }}
                      onClick={ResetFn}
                    >
                      {Labels.ResetFilter}
                    </Button>
                  </Item>
                </Grid>
                <Grid item xs={4}>
                  <Item>
                    <Button
                      variant="contained"
                      color="success"
                      sx={{
                        backgroundColor: "#1c614d",
                        border: "2px solid #1c614d",
                        width: "100%",
                        "&:hover": {
                          backgroundColor: "#1c614d",
                        },
                        "&:active": {
                          backgroundColor: "#1c614d",
                        },
                      }}
                      onClick={ApplyFilter}
                    >
                      {Labels.ApplyFilter}
                    </Button>
                  </Item>
                </Grid>
                <Grid item xs={4}>
                  <Item>
                    <Button
                      variant="contained"
                      color="success"
                      sx={{
                        backgroundColor: "#1c614d",
                        border: "2px solid #1c614d",
                        width: "100%",
                        "&:hover": {
                          backgroundColor: "#1c614d",
                        },
                        "&:active": {
                          backgroundColor: "#1c614d",
                        },
                      }}
                      onClick={() => {
                        setsavefilterview(true);
                      }}
                    >
                      {Labels.SaveFilter}
                    </Button>
                  </Item>
                </Grid>
                <Grid item xs={12}>
                  {savefilterview && (
                    <>
                      <br />
                      <Grid container spacing={2} alignItems="stretch">
                        <Grid item xs={9}>
                          <TextField
                            id="outlined-basic"
                            label={Labels.FilterName}
                            variant="outlined"
                            value={filtername}
                            onChange={(e) => setfiltername(e.target.value)}
                            fullWidth
                            sx={{ height: "100%" }}
                            InputProps={{ style: { height: "45px" } }}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Button
                            variant="contained"
                            color="success"
                            sx={{
                              backgroundColor: "#1c614d",
                              height: "100%",
                              width: "100%",
                              "&:hover": {
                                backgroundColor: "#1c614d",
                              },
                              "&:active": {
                                backgroundColor: "#1c614d",
                              },
                            }}
                            onClick={handleSaveFilter}
                            disabled={!filtername.length}
                          >
                            {Labels.Add}
                          </Button>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </>
            {/* Filter content */}
          </Item>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Item>
            <Accordion sx={{ boxShadow: "none" }} expanded={expandedsave}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                onClick={() => {
                  setexpandedsave(!expandedsave);
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 600, color: "#1c614d" }}
                >
                  {Labels.SavedFilters}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* Filter save */}
                <>
                  <List>
                    {SavedFilters.length ? (
                      SavedFilters?.map((a) => (
                        <ListItem
                          key={a?.sjid}
                          sx={{
                            padding: "5px",
                            margin: "5px",
                            borderRadius: "10px",
                            background: "#1c614d2f",

                            border:
                              selectedFilter === a ? "2px solid #1c614d" : null,
                          }}
                        >
                          <ListItemText
                            primary={a?.search_request.name}
                            sx={{ padding: "0px 5px", cursor: "default" }}
                          />

                          <Button
                            variant="contained"
                            color="success"
                            sx={{
                              backgroundColor: "#1c614d",
                              padding: "2px",
                              margin: "0px 5px",
                              cursor: "pointer",
                              "&:hover": {
                                backgroundColor: "#1c614d",
                              },
                              "&:active": {
                                backgroundColor: "#1c614d",
                              },
                            }}
                            onClick={(e) => {
                              selectFilter(a?.search_request);
                              handleItemClick(a);
                              applyfilterfn();
                            }}
                          >
                            {Labels.Apply}
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            sx={{
                              padding: "2px",
                              cursor: "pointer",
                            }}
                            onClick={() => deleteFilter(a?.sjid)}
                          >
                            {Labels.Delete}
                          </Button>
                        </ListItem>
                      ))
                    ) : (
                      <h3 style={{ margin: "0px" }}>
                        {Labels?.NoSavedFilters}
                      </h3>
                    )}
                  </List>
                </>
                {/* Filter save */}
              </AccordionDetails>
            </Accordion>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}

export default JobSearchFilter;
