import {
  HighlightOffOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  Dialog,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import CustomButton from "../../Components/CustomButton";
import { axiosAPI } from "../../Axios/Axios";
import {
  errorNotify,
  succesNotify,
  validatePassword,
} from "../../CommonCode/Commonfunc";
import { useSelector } from "react-redux";
import { profanityfn } from "../../Profanity/Profanity";

export default function EmployerPasswordChangeModal({ open, handleClose }) {
  const Labels = useSelector((state) => state.allLabels.labels);

  const [showPassword1, setShowPassword1] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1((show) => !show);
  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };
  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };
  const [showPassword3, setShowPassword3] = useState(false);
  const handleClickShowPassword3 = () => setShowPassword3((show) => !show);
  const handleMouseDownPassword3 = (event) => {
    event.preventDefault();
  };
  const [formData, setFormData] = useState({
    currentpassword: "",
    newpassword: "",
    confirmpassword: "",
  });

  const { currentpassword, newpassword, confirmpassword } = formData;
  const getNewPassword = (e) => {
    const value = e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  };

  const changePassword = async (e) => {
    e.preventDefault();
    const postData = {
      oldPassword: currentpassword,
      newPassword: newpassword,
      confirmPassword: confirmpassword,
      language:localStorage.getItem("language")
    };

    const minLength = 8;
    const maxLength = 30;
    const uppercaseRegex = /[A-Z]/;
    const numericRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*]/;

    if (!currentpassword) {
      errorNotify(Labels.currentPasswordEmpty);
    }

    if (newpassword.length < minLength || newpassword.length > maxLength) {
      errorNotify(Labels.Passwordmustbebetween8and30characters);
    } else if (!uppercaseRegex.test(newpassword)) {
      errorNotify(Labels.Passwordmustcontainatleastoneuppercaseletter);
    } else if (!numericRegex.test(newpassword)) {
      errorNotify(Labels.Passwordmustcontainatleastonenumericdigit);
    } else if (!specialCharRegex.test(newpassword)) {
      errorNotify(Labels.Passwordmustcontainatleastonespecialcharacter);
    } else if (newpassword !== confirmpassword) {
      errorNotify(Labels.passwordNotMatched);
    } else {
      const result = await axiosAPI.post("/changePassword", postData);
      const { success, message, data } = result.data;
      if (success === true) {
        succesNotify(message);
        handleClose(false);
      } else {
        errorNotify(data.error);
      }
    }
  };

  const inputStyle = {
    "-ms-reveal": {
      display: "none",
    },
    "-ms-clear": {
      display: "none",
    },
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 1.5 }}
      >
        <Typography variant="h6" sx={{ color: "#1C614E" }}>
          {Labels?.ChangePassword}
        </Typography>
        <HighlightOffOutlined
          onClick={() => handleClose(false)}
          sx={{ color: "#1C614E", cursor: "pointer" }}
        />
      </Stack>
      <Box sx={{ p: 1.5 }}>
        {/* -------------------- Current Password -------------------- */}
        <div style={{ margin: "10px 5px" }}>
          <style>
            {`
          input[type=password]::-ms-reveal,
          input[type=password]::-ms-clear {
            display: none;
          }
        `}
          </style>
          <label>{Labels?.CurrentPassword}</label>
          <br />
          <OutlinedInput
            type={showPassword1 ? "text" : "password"}
            placeholder="Current Password"
            sx={{
              width: "100%",
            }}
            name="currentpassword"
            value={currentpassword}
            onChange={(e) => getNewPassword(e)}
            inputMode="none"
            endAdornment={
              <InputAdornment position="end">
                {/* <div
                  onClick={() => setShowPassword1((show) => !show)}
                  style={{ cursor: "pointer" }}
                >
                  {showPassword1 ? "Hide" : "Show"}
                </div> */}
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword1}
                  onMouseDown={handleMouseDownPassword1}
                  edge="end"
                >
                  {showPassword1 ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        {/* -------------------- new Password -------------------- */}
        <div style={{ margin: "10px 5px" }}>
          <style>
            {`
          input[type=password]::-ms-reveal,
          input[type=password]::-ms-clear {
            display: none;
          }
        `}
          </style>
          <label>{Labels?.NewPassword}</label>
          <br />
          <OutlinedInput
            type={showPassword2 ? "text" : "password"}
            placeholder={Labels.NewPassword}
            sx={{
              width: "100%",
            }}
            name="newpassword"
            value={newpassword}
            inputMode="none"
            onChange={(e) => getNewPassword(e)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword2}
                  onMouseDown={handleMouseDownPassword2}
                  edge="end"
                >
                  {showPassword2 ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        {/* -------------------- confirm Password -------------------- */}
        <div style={{ margin: "10px 5px" }}>
          <style>
            {`
          input[type=password]::-ms-reveal,
          input[type=password]::-ms-clear {
            display: none;
          }
        `}
          </style>
          <label>{Labels?.confirmPassword}</label>
          <br />
          <OutlinedInput
            type={showPassword3 ? "text" : "password"}
            placeholder={Labels?.confirmPassword}
            sx={{
              width: "100%",
            }}
            name="confirmpassword"
            value={confirmpassword}
            // style={inputStyle}
            inputMode="none"
            onChange={(e) => getNewPassword(e)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword3}
                  onMouseDown={handleMouseDownPassword3}
                  edge="end"
                >
                  {showPassword3 ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
        <Stack direction="column" sx={{ my: 1, mx: 1.5, color: "#949191" }}>
          <Typography variant="caption">
            {" "}
            {Labels?.Atleast8Characters}
          </Typography>
          <Typography variant="caption">
            {Labels?.UpperandLower} (A-Z a-z)
          </Typography>
          <Typography variant="caption">{Labels?.Numbers0to9}</Typography>
          <Typography variant="caption">
            !@#$%^&* {Labels?.SpecialCharactersarevalid}
          </Typography>
        </Stack>
      </Box>
      <Stack direction="row" justifyContent="center" sx={{ p: 1.5 }}>
        <CustomButton
          label={Labels?.ChangePassword}
          style={{
            width: "100%",
          }}
          onClick={(e) => changePassword(e)}
        />
      </Stack>
    </Dialog>
  );
}
